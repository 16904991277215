import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import server from '@services/api';
import { cleanObjects } from '@utils/functions';

import {
  ClientsDocs,
  ClientsParams,
  ClientsLists,
  StoresDocs,
  StoreParams,
  StoresLists,
  TypesDocs,
  TypesParams,
  TypesLists,
  ParamsSearch,
  ParamsBankJobber,
  ReportData,
  ReportList,
  ParamsApplicationsSearch,
  JobListSearch,
  ShopperInvoiceSearch,
  PaymentVariablesQuantityShopperOrder,
  GeneratePaymentVariablesQuantityShopperOrderProps,
  GeneratePaymentVariablesQuantityLabourOrderProps,
  PaymentVariablesQuantityLabourOrder,
  AssignedPayrollSearch,
  LoadJobberCoinsProps,
} from './ts/report.interfaces';
import {
  applicationsReportRoute,
  paymentsReportRoute,
  reportClientsRoute,
  reportStoresRoute,
  reportTypesRoute,
  paymentsReportBankJobberRoute,
  jobListReportRoute,
  paymentsVariableQuantityRoute,
  profesionalProfileRoute,
  shopperInvoiceRoute,
  sendInvoiceSchemaReportRoute,
  vehiclesRoute,
  sendInvoiceSchemaHoursReportRoute,
  paymentsVariableQuantityShopperOrderRoute,
  generatePaymentVariablesQuantityShopperOrderRoute,
  shopperInvoiceSmuRoute,
  sendMoneyOrdersShopperReportRoute,
  shopperInvoiceWalmartRoute,
  generatePaymentVariablesQuantityLabourOrderRoute,
  sendMoneyOrdersLabourReportRoute,
  createPickerFinishedRoute,
  transformShopperDriverRoute,
  sendAssignedPayrollReportRoute,
  loadJobberCoinsRoute,
  sendApplicationsReportRoute,
} from './ts/routes';
import { RequestStatusEnum } from '@utils/enum';
import i18n from '@i18n/index';

export const reportAdapter = createEntityAdapter();

export const initialState = reportAdapter.getInitialState({
  clients: { docs: [] as [] | ClientsDocs, loading: false },
  stores: { docs: [] as [] | StoresDocs, loading: false },
  types: { docs: [] as [] | TypesDocs, loading: false },
  reportData: { isSuccess: undefined as boolean | ReportData, loading: false },
  applicationTaskReportValues: { isSuccess: undefined as boolean | ReportData, loading: false },
  assignedPayrollReportData: RequestStatusEnum.IDLE,
  applicationsReportData: { data: '', loading: false },
  paramsSearch: [] as ParamsSearch,
  loadingAlert: null as boolean,
  paymentAlert: null as boolean,
  loading: RequestStatusEnum.IDLE,
  error: null,
  valor: 0,
  uploadStatus: RequestStatusEnum.IDLE,
  generateStatus: RequestStatusEnum.IDLE,
  moneyOrdersShopperReportData: { message: '', type: '', loading: false },
  moneyOrdersLabourReportData: { message: '', type: '', loading: false },
  createPickerFinishedData: {
    message: '',
    type: '',
    status: RequestStatusEnum.IDLE,
  },
  transformShopperDriverData: {
    message: '',
    type: '',
    status: RequestStatusEnum.IDLE,
  },
  manageJobberCoinsData: {
    message: '',
    type: '',
    status: RequestStatusEnum.IDLE,
  },
});

// get customers
export const getClients = createAsyncThunk(
  'clients/getClients',
  async (params: ClientsParams, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ClientsLists = await server.get(reportClientsRoute(), {
      params: params,
    });
    return response?.data;
  }
);

// get venues
export const getStores = createAsyncThunk(
  'stores/getStores',
  async (params: StoreParams, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: StoresLists = await server.get(reportStoresRoute(), {
      params: params,
    });
    return response?.data;
  }
);

// get types of tasks
export const getTypes = createAsyncThunk(
  'types/getTypes',
  async (params: TypesParams, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: TypesLists = await server.get(reportTypesRoute(), {
      params: params,
    });
    return response?.data;
  }
);

// send report
export const sendReport = createAsyncThunk(
  'report/getBankReport',
  async (params: ParamsSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(paymentsReportRoute(params.documentCode), {
      params: cleanObjects(params.data),
    });
    return response?.data;
  }
);

// send report bank jobber
export const sendBankReport = createAsyncThunk(
  'report/getBankReport',
  async (params: ParamsBankJobber, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(paymentsReportBankJobberRoute(params));
    return response?.data;
  }
);

// send report profile jobber
export const sendProfileReport = createAsyncThunk('report/sendProfileReport', async () => {
  const response = await server.get(profesionalProfileRoute());
  return response?.data;
});

// send vehicles
export const sendVehicles = createAsyncThunk('report/sendVehicles', async () => {
  const response = await server.get(vehiclesRoute());
  return response?.data;
});

// send aplication
export const sendApplicationsReport = createAsyncThunk(
  'report/sendApplicationsReport',
  async (params: ParamsApplicationsSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(applicationsReportRoute(), {
      params: params,
    });

    return response?.data;
  }
);

// send shopper invoice report
export const sendShopperInvoiceReport = createAsyncThunk(
  'report/sendShopperInvoiceReport',
  async (params: ShopperInvoiceSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(shopperInvoiceRoute(), {
      params: cleanObjects(params),
    });
    return response?.data;
  }
);

// send shopper invoice smu report
export const sendShopperInvoiceSmuReport = createAsyncThunk(
  'report/sendShopperInvoiceSmuReport',
  async (params: ShopperInvoiceSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(shopperInvoiceSmuRoute(), {
      params: cleanObjects(params),
    });
    return response?.data;
  }
);

// send shopper invoice walmart report
export const sendShopperInvoiceWalmartReport = createAsyncThunk(
  'report/sendShopperInvoiceWalmartReport',
  async (params: ShopperInvoiceSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(shopperInvoiceWalmartRoute(), {
      params: cleanObjects(params),
    });
    return response?.data;
  }
);

export const sendPaymentVariablesQuantityLabourOrder = createAsyncThunk(
  'report/sendPaymentVariablesQuantityLabourOrder',
  async (body: PaymentVariablesQuantityLabourOrder) => {
    const response: ReportList = await server.post(paymentsVariableQuantityRoute(), body);
    return response?.data;
  }
);

export const generatePaymentVariablesQuantityShopperOrder = createAsyncThunk(
  'report/generatePaymentVariablesQuantityShopperOrder',
  async (params: GeneratePaymentVariablesQuantityShopperOrderProps) => {
    const response: ReportList = await server.get(
      generatePaymentVariablesQuantityShopperOrderRoute(),
      { params }
    );
    return response?.data;
  }
);

export const generatePaymentVariablesQuantityLabourOrder = createAsyncThunk(
  'report/generatePaymentVariablesQuantityLabourOrder',
  async (params: GeneratePaymentVariablesQuantityLabourOrderProps) => {
    const response: ReportList = await server.get(
      generatePaymentVariablesQuantityLabourOrderRoute(),
      { params }
    );
    return response?.data;
  }
);

export const sendPaymentVariablesQuantityShopperOrder = createAsyncThunk(
  'report/sendPaymentVariablesQuantityShopperOrder',
  async (body: PaymentVariablesQuantityShopperOrder) => {
    const response: ReportList = await server.post(
      paymentsVariableQuantityShopperOrderRoute(),
      body
    );
    return response?.data;
  }
);

// send shopperInvoice
export const sendJobListReport = createAsyncThunk(
  'report/getJobListReport',
  async (params: JobListSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(jobListReportRoute(), {
      params: cleanObjects(params),
    });
    return response?.data;
  }
);

export const sendInvoiceSchemaReport = createAsyncThunk(
  'report/sendInvoiceSchemaReport',
  async (params: JobListSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(sendInvoiceSchemaReportRoute(), {
      params: cleanObjects(params),
    });
    return response?.data;
  }
);

export const sendTaskApplicationsReport = createAsyncThunk(
  'report/sendTaskApplicationsReport',
  async (params: JobListSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(sendApplicationsReportRoute(), {
      params: {
        ...cleanObjects(params),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });
    return response?.data;
  }
);

export const sendInvoiceSchemaHoursReport = createAsyncThunk(
  'report/sendInvoiceSchemaHoursReport',
  async (params: JobListSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(sendInvoiceSchemaHoursReportRoute(), {
      params: cleanObjects(params),
    });
    return response?.data;
  }
);

export const sendAssignedPayrollReport = createAsyncThunk(
  'report/sendAssignedPayrollReport',
  async (params: AssignedPayrollSearch, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server.get(sendAssignedPayrollReportRoute(), {
      params: cleanObjects(params),
    });
    return response?.data;
  }
);

export const sendMoneyOrdersShopperReport = createAsyncThunk(
  'report/sendMoneyOrdersShopperReport',
  async (params: any, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server({
      method: 'get',
      url: sendMoneyOrdersShopperReportRoute(),
      params,
    });
    return response.data;
  }
);

export const sendMoneyOrdersLabourReport = createAsyncThunk(
  'report/sendMoneyOrdersLabourReport',
  async (params: any, { getState }: any) => {
    const { loading } = getState().report;
    if (loading !== RequestStatusEnum.PENDING) return;

    const response: ReportList = await server({
      method: 'get',
      url: sendMoneyOrdersLabourReportRoute(),
      params,
    });
    return response.data;
  }
);

export const createPickerFinished = createAsyncThunk(
  'report/createPickerFinished',
  async (body: PaymentVariablesQuantityLabourOrder) => {
    const response: ReportList = await server.post(createPickerFinishedRoute(), body);
    return response?.data;
  }
);

export const loadJobberCoins = createAsyncThunk(
  'report/loadJobberCoins',
  async (body: LoadJobberCoinsProps) => {
    const response: ReportList = await server.put(loadJobberCoinsRoute(), body);

    return response?.data;
  }
);

export const transformShopperDriver = createAsyncThunk(
  'report/transformShopperDriver',
  async (body: PaymentVariablesQuantityLabourOrder) => {
    const response: ReportList = await server.put(transformShopperDriverRoute(), body);
    return response?.data;
  }
);

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setAlertLoading: (state) => {
      state.loadingAlert = null;
    },
    clearPaymentAlert: (state) => {
      state.paymentAlert = null;
    },
    clearApplicationsReport: (state) => {
      state.applicationsReportData = { loading: false, data: '' };
    },
    clearMoneyOrdersShopperReport: (state) => {
      state.moneyOrdersShopperReportData = { loading: false, message: '', type: '' };
    },
    clearMoneyOrdersLabourReport: (state) => {
      state.moneyOrdersLabourReportData = { loading: false, message: '', type: '' };
    },
    clearUploadState: (state) => {
      state.uploadStatus = RequestStatusEnum.IDLE;
    },
    clearGenerateState: (state) => {
      state.generateStatus = RequestStatusEnum.IDLE;
    },
    clearTransformShopperDriverDataState: (state) => {
      state.transformShopperDriverData = { message: '', type: '', status: RequestStatusEnum.IDLE };
    },
    clearAssignedPayrollReport: (state) => {
      state.assignedPayrollReportData = RequestStatusEnum.IDLE;
    },
    clearManageJobberCoinsData: (state) => {
      state.manageJobberCoinsData = { message: '', type: '', status: RequestStatusEnum.IDLE };
    },
  },
  extraReducers: {
    //  get customers
    [String(getClients.pending)]: (state) => {
      state.clients.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getClients.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.clients = { loading: false, ...action.payload.data };
      }
    },
    [String(getClients.rejected)]: (state, action) => {
      state.clients.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    // get venues
    [String(getStores.pending)]: (state) => {
      state.stores.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getStores.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.stores = { loading: false, ...action.payload.data };
      }
    },
    [String(getStores.rejected)]: (state, action) => {
      state.stores.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    // get types of tasks
    [String(getTypes.pending)]: (state) => {
      state.types.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(getTypes.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.types = { loading: false, ...action.payload.data };
      }
    },
    [String(getTypes.rejected)]: (state, action) => {
      state.types.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.error = action.error;
      }
    },
    // send report
    [String(sendReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },
    // send report bank jobber
    [String(sendBankReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendBankReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendBankReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },
    // send aplicantion
    [String(sendApplicationsReport.pending)]: (state) => {
      state.applicationsReportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendApplicationsReport.fulfilled)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.applicationsReportData = { loading: false, ...action.payload };
        state.loadingAlert = true;
      }
    },
    [String(sendApplicationsReport.rejected)]: (state, action) => {
      state.applicationsReportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.applicationsReportData = { loading: false, data: '400' };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },
    // send jobList report
    [String(sendJobListReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendJobListReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendJobListReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },

    [String(sendPaymentVariablesQuantityLabourOrder.pending)]: (state) => {
      state.uploadStatus = RequestStatusEnum.PENDING;
    },
    [String(sendPaymentVariablesQuantityLabourOrder.fulfilled)]: (state) => {
      state.uploadStatus = RequestStatusEnum.SUCCESS;
    },
    [String(sendPaymentVariablesQuantityLabourOrder.rejected)]: (state, action) => {
      state.uploadStatus = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(sendPaymentVariablesQuantityShopperOrder.pending)]: (state) => {
      state.uploadStatus = RequestStatusEnum.PENDING;
    },
    [String(sendPaymentVariablesQuantityShopperOrder.fulfilled)]: (state) => {
      state.uploadStatus = RequestStatusEnum.SUCCESS;
    },
    [String(sendPaymentVariablesQuantityShopperOrder.rejected)]: (state, action) => {
      state.uploadStatus = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(createPickerFinished.pending)]: (state) => {
      state.createPickerFinishedData = {
        status: RequestStatusEnum.PENDING,
        type: '',
        message: '',
      };
    },
    [String(createPickerFinished.fulfilled)]: (state) => {
      state.createPickerFinishedData = {
        status: RequestStatusEnum.SUCCESS,
        type: 'success',
        message: i18n.t('report.createPickerFinished.alert.success'),
      };
    },
    [String(createPickerFinished.rejected)]: (state, action) => {
      state.createPickerFinishedData = {
        status: RequestStatusEnum.ERROR,
        type: 'error',
        message: i18n.t('report.createPickerFinished.alert.error'),
      };
      state.error = action.error;
    },

    [String(loadJobberCoins.pending)]: (state) => {
      state.manageJobberCoinsData = {
        status: RequestStatusEnum.PENDING,
        type: '',
        message: '',
      };
    },
    [String(loadJobberCoins.fulfilled)]: (state) => {
      state.manageJobberCoinsData = {
        status: RequestStatusEnum.SUCCESS,
        type: 'success',
        message: i18n.t('report.manageJobberCoins.alert.success'),
      };
    },
    [String(loadJobberCoins.rejected)]: (state, action) => {
      state.manageJobberCoinsData = {
        status: RequestStatusEnum.ERROR,
        type: 'error',
        message: i18n.t('report.manageJobberCoins.alert.error'),
      };
      state.error = action.error;
    },

    [String(transformShopperDriver.pending)]: (state) => {
      state.transformShopperDriverData = {
        status: RequestStatusEnum.PENDING,
        type: '',
        message: '',
      };
    },
    [String(transformShopperDriver.fulfilled)]: (state) => {
      state.transformShopperDriverData = {
        status: RequestStatusEnum.SUCCESS,
        type: 'success',
        message: i18n.t('report.transformShopperDriver.alert.success'),
      };
    },
    [String(transformShopperDriver.rejected)]: (state, action) => {
      state.transformShopperDriverData = {
        status: RequestStatusEnum.ERROR,
        type: 'error',
        message: i18n.t('report.transformShopperDriver.alert.error'),
      };
      state.error = action.error;
    },

    [String(generatePaymentVariablesQuantityShopperOrder.pending)]: (state) => {
      state.generateStatus = RequestStatusEnum.PENDING;
    },
    [String(generatePaymentVariablesQuantityShopperOrder.fulfilled)]: (state) => {
      state.generateStatus = RequestStatusEnum.SUCCESS;
    },
    [String(generatePaymentVariablesQuantityShopperOrder.rejected)]: (state, action) => {
      state.generateStatus = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(generatePaymentVariablesQuantityLabourOrder.pending)]: (state) => {
      state.generateStatus = RequestStatusEnum.PENDING;
    },
    [String(generatePaymentVariablesQuantityLabourOrder.fulfilled)]: (state) => {
      state.generateStatus = RequestStatusEnum.SUCCESS;
    },
    [String(generatePaymentVariablesQuantityLabourOrder.rejected)]: (state, action) => {
      state.generateStatus = RequestStatusEnum.ERROR;
      state.error = action.error;
    },

    [String(sendProfileReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendProfileReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendProfileReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },
    [String(sendVehicles.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendVehicles.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendVehicles.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },
    // send shopper invoice report
    [String(sendShopperInvoiceReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendShopperInvoiceReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendShopperInvoiceReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },

    [String(sendShopperInvoiceSmuReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendShopperInvoiceSmuReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendShopperInvoiceSmuReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },

    [String(sendShopperInvoiceWalmartReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendShopperInvoiceWalmartReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendShopperInvoiceWalmartReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },
    [String(sendInvoiceSchemaReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendInvoiceSchemaReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendInvoiceSchemaReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },

    [String(sendTaskApplicationsReport.pending)]: (state) => {
      state.applicationTaskReportValues = {
        loading: true,
        isSuccess: undefined,
      };
      state.loading = RequestStatusEnum.PENDING;
    },
    [String(sendTaskApplicationsReport.fulfilled)]: (state) => {
      state.loading = RequestStatusEnum.IDLE;
      state.applicationTaskReportValues = {
        loading: false,
        isSuccess: true,
      };
      state.loadingAlert = true;
    },
    [String(sendTaskApplicationsReport.rejected)]: (state, action) => {
      state.applicationTaskReportValues = {
        loading: false,
        isSuccess: false,
      };
      state.loading = RequestStatusEnum.IDLE;
      state.loadingAlert = true;
      state.error = action.error?.message;
    },

    [String(sendInvoiceSchemaHoursReport.pending)]: (state) => {
      state.reportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendInvoiceSchemaHoursReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: true };
        state.loadingAlert = true;
      }
    },
    [String(sendInvoiceSchemaHoursReport.rejected)]: (state, action) => {
      state.reportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.reportData = { loading: false, isSuccess: false };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },

    [String(sendAssignedPayrollReport.pending)]: (state) => {
      state.assignedPayrollReportData = RequestStatusEnum.PENDING;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendAssignedPayrollReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.assignedPayrollReportData = RequestStatusEnum.SUCCESS;
      }
    },
    [String(sendAssignedPayrollReport.rejected)]: (state, action) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.assignedPayrollReportData = RequestStatusEnum.ERROR;
        state.error = action.error;
      }
    },

    [String(sendMoneyOrdersShopperReport.pending)]: (state) => {
      state.moneyOrdersShopperReportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendMoneyOrdersShopperReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrdersShopperReportData = {
          loading: false,
          message: i18n.t('payments.moneyOrderListShopper.alerts.success'),
          type: 'success',
        };
        state.loadingAlert = true;
      }
    },
    [String(sendMoneyOrdersShopperReport.rejected)]: (state, action) => {
      state.moneyOrdersShopperReportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrdersShopperReportData = {
          loading: false,
          message: i18n.t('payments.moneyOrderListShopper.alerts.error'),
          type: 'error',
        };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },

    [String(sendMoneyOrdersLabourReport.pending)]: (state) => {
      state.moneyOrdersLabourReportData.loading = true;
      if (state.loading === RequestStatusEnum.IDLE) {
        state.loading = RequestStatusEnum.PENDING;
      }
    },
    [String(sendMoneyOrdersLabourReport.fulfilled)]: (state) => {
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrdersLabourReportData = {
          loading: false,
          message: i18n.t('payments.moneyOrderListShopper.alerts.success'),
          type: 'success',
        };
        state.loadingAlert = true;
      }
    },
    [String(sendMoneyOrdersLabourReport.rejected)]: (state, action) => {
      state.moneyOrdersLabourReportData.loading = false;
      if (state.loading === RequestStatusEnum.PENDING) {
        state.loading = RequestStatusEnum.IDLE;
        state.moneyOrdersLabourReportData = {
          loading: false,
          message: i18n.t('payments.moneyOrderListShopper.alerts.error'),
          type: 'error',
        };
        state.loadingAlert = true;
        state.error = action.error;
      }
    },
  },
});

export const {
  setAlertLoading,
  clearPaymentAlert,
  clearApplicationsReport,
  clearUploadState,
  clearGenerateState,
  clearMoneyOrdersShopperReport,
  clearMoneyOrdersLabourReport,
  clearTransformShopperDriverDataState,
  clearAssignedPayrollReport,
  clearManageJobberCoinsData,
} = reportSlice.actions;

export default reportSlice.reducer;
