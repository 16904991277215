export const reportClientsRoute = (): string => 'business/enterprises';
export const reportStoresRoute = (): string => 'business/stores';
export const reportTypesRoute = (): string => 'job-config/job-types';
export const paymentsReportRoute = (documentCode: string): string =>
  `reports/jobbers/payments/${documentCode}`;
export const paymentsReportBankJobberRoute = (params): any =>
  `reports/jobbers/jobbers-info/${params.documentCode}?timezone=${params.timezone}`;
export const applicationsReportRoute = (): string => 'reports/jobbers/related-to-job';
export const jobListReportRoute = (): string => 'reports/jobs/jobList';
export const paymentsVariableQuantityRoute = (): string => 'reports/staffs/load-quantity';
export const profesionalProfileRoute = (): string => 'reports/jobbers/jobbers-info/tsc_data';
export const vehiclesRoute = (): string => 'reports/jobbers/jobbers-info/vehicles';
export const shopperInvoiceRoute = (): string => 'reports/staffs/shopper-invoice';
export const shopperInvoiceSmuRoute = (): string => 'reports/staffs/shopper-invoice-smu';
export const shopperInvoiceWalmartRoute = (): string => 'reports/staffs/shopper-invoice-walmart';
export const sendInvoiceSchemaReportRoute = (): string => 'reports/staffs/invoice';
export const sendApplicationsReportRoute = (): string => 'reports/staffs/applications-report';
export const sendInvoiceSchemaHoursReportRoute = (): string => 'reports/staffs/client-invoice';
export const sendAssignedPayrollReportRoute = (): string => 'reports/staffs/assigned-payroll';
export const sendMoneyOrdersShopperReportRoute = (): string =>
  'reports/staffs/money-orders-shopper-info';
export const sendMoneyOrdersLabourReportRoute = (): string =>
  'reports/staffs/money-orders-labour-info';
export const paymentsVariableQuantityShopperOrderRoute = (): string =>
  'reports/staffs/load-quantity-shopper-order';
export const generatePaymentVariablesQuantityShopperOrderRoute = (): string =>
  'reports/staffs/load-quantity-shopper-order-template';
export const generatePaymentVariablesQuantityLabourOrderRoute = (): string =>
  'reports/staffs/load-quantity-labour-order-template';
export const createPickerFinishedRoute = (): string => 'reports/staffs/load-picker-orders';
export const transformShopperDriverRoute = (): string => 'reports/staffs/transform-shopper-driver';
export const loadJobberCoinsRoute = (): string => 'reports/staffs/coins';
