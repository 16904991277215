import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { RequestStatusEnum } from '@utils/enum';
import {
  actionGetNpsIndicatorsRoute,
  getOnboardingDataRoute,
  getOperationalDataHourlyRoute,
  getOperationalDataRoute,
  getQualificationsTaksRoute,
  getRankingTasksRoute,
  getShopperDataHourlyRoute,
} from './ts/routes';
import server from '@services/api';
import { formatProbabilitiesData, formatProblemsData } from '@utils/graphConfig';
import { OnboardingFiltersStateType } from './ts/dashboard';
import { OperationalValueParams } from '../../components/templates/features/dashboard/OperationalDataTab/OperationalDataTab';

export const dashboardAdapter = createEntityAdapter();

export const initialState: any = dashboardAdapter.getInitialState({
  npsIndicators: {
    loading: RequestStatusEnum.IDLE,
    data: {},
    backup: {},
    totals: {},
    currentDate: null,
    error: null,
  },
  qualificationsTasks: {
    loading: RequestStatusEnum.IDLE,
    loadingStars: RequestStatusEnum.IDLE,
    data: {},
    error: null,
  },
  rankingTasks: {
    loading: RequestStatusEnum.IDLE,
    data: [],
    error: null,
  },
  onboarding: {
    data: {},
    error: null,
    total: 0,
  },
  operations: {
    data: [],
    error: null,
    loading: false,
    dataHourly: [],
    shopperDataHourly: [],
  },
});

export const getNpsIndicators = createAsyncThunk(
  'dashboard/getNpsIndicators',
  async (
    params: { startDate: string; endDate: string; firstCharge: boolean; date?: any },
    { getState }: any
  ) => {
    const { loading, backup } = getState().dashboard?.npsIndicators;
    if (loading !== RequestStatusEnum.PENDING) return;

    let tempData = {};
    if (params.firstCharge) {
      const response = await server.get(actionGetNpsIndicatorsRoute(), { params });

      // subtract 1 month
      const aux = response.data.data;
      const years = Object.keys(aux).sort((prev, next) => (prev > next ? 1 : -1));

      if (years.length > 1) {
        delete aux[years[0]][moment().add(1, 'month').month()];
        aux[years[1]][moment().add(1, 'month').month()] = {};
      } else {
        aux[years[0]][moment().add(1, 'month').month()];
      }

      Object.entries(aux).forEach(([year, months]) => {
        tempData = { ...tempData, [year]: {} };
        Object.entries(months).forEach(([month, values]) => {
          const pass = Number(month) === 0;
          const nYear = pass ? Number(year) - 1 : year;
          const nMonth = pass ? 11 : Number(month) - 1;

          if (tempData[nYear]) {
            tempData[nYear][nMonth] = values;
          } else {
            tempData[nYear] = {};
            tempData[nYear][nMonth] = values;
          }
        });
      });
    } else {
      tempData = backup;
    }

    const probabilities = formatProbabilitiesData(tempData);
    const {
      data,
      problems,
      previousMonths,
      totalJobbersxMonth,
      totalProblemsxMonth,
      totlaProblemsKindxMonth,
      allJobbers,
    } = formatProblemsData(tempData, params.date);

    return {
      data: {
        probabilities,
        hadProblems: { data, previousMonths, problems },
        totals: { totalJobbersxMonth, totalProblemsxMonth, totlaProblemsKindxMonth, allJobbers },
        backup: tempData,
        currentDate: moment(params.date),
      },
    };
  }
);

export const getQualificationsTaks = createAsyncThunk(
  'dashboard/getQualificationsTaks',
  async (params: object, { getState }: any) => {
    const { loading } = getState().dashboard?.qualificationsTasks;
    if (loading === RequestStatusEnum.IDLE) return;
    const response = await server.get(getQualificationsTaksRoute(), { params });
    return response;
  }
);

export const getRankingTasks = createAsyncThunk(
  'dashboard/getRankingTasks',
  async (params: object, { getState }: any) => {
    const { loading } = getState().dashboard?.rankingTasks;
    if (loading === RequestStatusEnum.IDLE) return;
    const response = await server.get(getRankingTasksRoute(), { params });
    return response;
  }
);

export const getOnboardingData = createAsyncThunk(
  'dashboard/getOnboardingData',
  async ({ filters }: { filters: OnboardingFiltersStateType; key: string }) => {
    const response = await server.get(getOnboardingDataRoute(), { params: filters });
    return response.data;
  }
);

export const getOperationalData = createAsyncThunk(
  'dashboard/getOperationalData',
  async (filters: OperationalValueParams) => {
    const response = await server.get(getOperationalDataRoute(), { params: filters });
    return response.data;
  }
);

export const getOperationalHourlyData = createAsyncThunk(
  'dashboard/getOperationalHourlyData',
  async (dates: OperationalValueParams) => {
    const response = await server.get(getOperationalDataHourlyRoute(dates));
    return response.data;
  }
);

export const getShopperHourlyData = createAsyncThunk(
  'dashboard/getShopperHourlyData',
  async (params: OperationalValueParams) => {
    const response = await server.get(getShopperDataHourlyRoute(), { params });
    return response.data;
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    reloadStars: (state) => {
      state.qualificationsTasks.loadingStars = RequestStatusEnum.PENDING;
    },
    cancelReload: (state) => {
      state.qualificationsTasks.loadingStars = RequestStatusEnum.IDLE;
    },
    resetOnboardingData: (state) => {
      state.onboarding.data = {};
      state.onboarding.error = null;
      state.onboarding.total = 0;
    },
    resetOnboardingDataKey: (state, action) => {
      delete state.onboarding.data[action.payload];
      state.onboarding.error = null;

      const maxValue = Math.max.apply(null, Object.values(state.onboarding.data));
      state.onboarding.total = maxValue;
    },
  },
  extraReducers: {
    [String(getNpsIndicators.pending)]: (state) => {
      state.npsIndicators.loading = RequestStatusEnum.PENDING;
    },
    [String(getNpsIndicators.fulfilled)]: (state, action) => {
      state.npsIndicators.loading = RequestStatusEnum.IDLE;
      state.npsIndicators.data = action.payload.data || {};
      state.npsIndicators.backup = action.payload.data.backup || {};
      state.npsIndicators.totals = action.payload.data.totals || {};
      state.npsIndicators.currentDate = action.payload.data.currentDate || {};
    },
    [String(getNpsIndicators.rejected)]: (state, action) => {
      state.npsIndicators.loading = RequestStatusEnum.IDLE;
      state.npsIndicators.error = action.error.message;
    },

    [String(getQualificationsTaks.pending)]: (state) => {
      state.qualificationsTasks.loading = RequestStatusEnum.PENDING;
      state.qualificationsTasks.loadingStars = RequestStatusEnum.PENDING;
      state.qualificationsTasks.data = {};
      state.qualificationsTasks.error = null;
    },
    [String(getQualificationsTaks.fulfilled)]: (state, action) => {
      state.qualificationsTasks.loading = RequestStatusEnum.IDLE;
      state.qualificationsTasks.loadingStars = RequestStatusEnum.IDLE;
      state.qualificationsTasks.data = action.payload?.data?.data || {};
    },
    [String(getQualificationsTaks.rejected)]: (state, action) => {
      state.qualificationsTasks.loading = RequestStatusEnum.IDLE;
      state.qualificationsTasks.loadingStars = RequestStatusEnum.IDLE;
      state.qualificationsTasks.error = action.error?.message;
    },

    [String(getRankingTasks.pending)]: (state) => {
      state.rankingTasks.loading = RequestStatusEnum.PENDING;
      state.rankingTasks.data = [];
      state.rankingTasks.error = null;
    },
    [String(getRankingTasks.fulfilled)]: (state, action) => {
      state.rankingTasks.loading = RequestStatusEnum.IDLE;
      state.rankingTasks.data = action.payload?.data?.data || [];
    },
    [String(getRankingTasks.rejected)]: (state, action) => {
      state.rankingTasks.loading = RequestStatusEnum.IDLE;
      state.rankingTasks.error = action.error?.message;
    },

    // Onboarding data
    [String(getOnboardingData.pending)]: (state) => {
      state.onboarding.error = null;
    },
    [String(getOnboardingData.fulfilled)]: (state, action) => {
      state.onboarding.error = null;
      if (action.meta.arg.key) state.onboarding.data[action.meta.arg.key] = action.payload.data;

      const maxValue = Math.max.apply(null, Object.values(state.onboarding.data));
      state.onboarding.total = maxValue;
    },
    [String(getOnboardingData.rejected)]: (state, action) => {
      state.onboarding.error = action.error?.message;
    },

    // Operational data
    [String(getOperationalData.pending)]: (state) => {
      state.operations.error = null;
      state.operations.loading = true;
    },
    [String(getOperationalData.fulfilled)]: (state, action) => {
      state.operations.error = null;
      state.operations.data = action?.payload?.data;
      state.operations.loading = false;
    },
    [String(getOperationalData.rejected)]: (state, action) => {
      state.operations.error = action.error?.message;
      state.operations.loading = false;
    },

    // Operational data Hourly
    [String(getOperationalHourlyData.pending)]: (state) => {
      state.operations.error = null;
      state.operations.loading = true;
    },
    [String(getOperationalHourlyData.fulfilled)]: (state, action) => {
      state.operations.error = null;
      state.operations.dataHourly = action?.payload?.data;
      state.operations.loading = false;
    },
    [String(getOperationalHourlyData.rejected)]: (state, action) => {
      state.operations.error = action.error?.message;
      state.operations.loading = false;
    },

    // Shopper data Hourly
    [String(getShopperHourlyData.pending)]: (state) => {
      state.operations.error = null;
      state.operations.loading = true;
    },
    [String(getShopperHourlyData.fulfilled)]: (state, action) => {
      state.operations.error = null;
      state.operations.shopperDataHourly = action?.payload?.data;
      state.operations.loading = false;
    },
    [String(getShopperHourlyData.rejected)]: (state, action) => {
      state.operations.error = action.error?.message;
      state.operations.loading = false;
    },
  },
});

export const { reloadStars, cancelReload, resetOnboardingData, resetOnboardingDataKey } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
