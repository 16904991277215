export default {
  dashboard: {
    title: 'Estadísticas',
    subtitle: 'Revisa los indicadores de Time Jobs y empresas.',
    title2: 'Indicadores',
    tabs: {
      comingSoon: 'Próximamente',
      nps: {
        title: 'NPS',
        indicators: {
          title: 'Seguimiento de indicadores',
          filters: { month: { title: 'Mes', placeholder: 'Selecciona' } },
        },
        charts: {
          mixed: {
            title: 'Probabilidad de recomendar a Time Jobs',
            subtitle: 'Muestra de {{count}} Jobber correspondiente a {{month}}',
            subtitle_plural: 'Muestra de {{count}} Jobbers correspondiente a {{month}}',
            labels: {
              '1_6': 'Notas 1 a 6',
              '7_8': 'Notas 7 y 8',
              '9_10': 'Notas 9 y 10',
              nps: 'NPS',
            },
            alert: [
              'A contar de {{month}} {{year}}, ',
              'el NPS se pregunta en la App a todos los Jobbers activos del mes, siendo su respuesta opcional. Está disponible 1 semana para su respuesta.',
              'Muestra de {{count}} Jobbers de un total de {{total}} Jobbers activos ({{percentage}}%).',
            ],
          },
          problemsPie: {
            title:
              '¿Has tenido un problema con Time Jobs o con las tareas realizadas en los últimos 30 días?',
            subtitle: '{{count}} respuesta',
            subtitle_plural: '{{count}} respuestas',
          },
          problemsBar: {
            title: 'Específicamente ¿Con qué?',
            subtitle: '{{count}} respuesta',
            subtitle_plural: '{{count}} respuestas',
            name: 'Jobbers',
            labels: {
              MISTREATMENT_DURING_JOB: 'Trato durante la tarea',
              INDUCTIONS: 'Inducciones',
              REGISTRATION_PROCESS: 'Proceso de registro',
              JOB_PLACE: 'Lugar de la tarea',
              APPLICATION_FOR_THE_FIRST_JOB: 'Postulación primera tarea',
              PAYMENTS: 'Pagos',
              OPERATION_OF_THE_APP: 'Funcionamiento de la aplicación',
              JOB_ASSIGNMENT: 'Asignación de tareas',
              INCOMES: 'Ingresos',
              SHOPPER: 'Shopper',
              OTHER: 'Otros',
            },
          },
          lastMonths: {
            title: 'Problemas en los últimos meses',
          },
          download: 'Ver informe NPS completo',
        },
      },
      ratings: {
        title: 'Calificación de tareas',
        selects: {
          vertical: 'Vertical',
          enterprise: 'Cliente',
          store: 'Sede',
          typeTask: 'Tipo de tarea',
          placeholder: 'Selecciona',
        },
        latestMonths: 'Resultados últimos {{months}} meses',
        lastUpdated: 'Actualizado al {{date}}',
        indicators: {
          title: 'Seguimiento de indicadores',
          subtitle: 'Resultados para:',
          detail: '{{indicators}} (Últimos {{months}}) meses',
          positive: {
            title: 'Calificaciones positivas (4 y 5)',
            subtitle: '{{value}} calificaciones',
          },
          negative: {
            title: 'Calificaciones negativas (1 y 3)',
            subtitle: '{{value}} calificaciones',
          },
        },
        ranking: {
          title: 'Ranking de tareas',
          subtitle: 'Revisa el ranking de las tareas y sedes mejor evaluadas.',
          latestResult: 'Resultado últimos {{days}} días',
          table: ['Tipo de tarea', 'Cliente', 'Sede', 'Respuestas', 'Promedio'],
        },
        charts: {
          mixed: {
            labels: {
              one: '1 estrella',
              two: '2 estrellas',
              three: '3 estrellas',
              four: '4 estrellas',
              five: '5 estrellas',
              avg: 'Calificación promedio',
            },
          },
          problemsBar: {
            labels: {
              ACCESS_OR_SITE_REFERENCE: 'Acceso o lugar de referencia',
              ACCESS_OR_SITE_REFERENCE_AND_LOCATION_IN_APP:
                'Acceso o lugar de referencia y ubicación en la App',
              APP_OPERATION: 'Operación de la App',
              AVAILABLE_ORDERS: 'Pedidos disponibles',
              AVAILABLE_PRODUCTS: 'Productos disponibles',
              AVAILABLE_SUPPLIES: 'Suministros disponibles',
              BATHROOM: 'Baños',
              COORDINATORS_TREATMENT: 'Trato de coordinadores',
              DELIVERY_DISTANCE: 'Distancia de la entrega',
              DISPATCH_APP: 'Despacho en la App',
              EXPLANATION_OF_TASK: 'Explicación de la tarea',
              LOCATION: 'Ubicación',
              NEARBY_BUS: 'Cercanía de bus',
              ORDER_QUANTITY: 'Cantidad de pedidos',
              OTHER: 'Otros',
              PARKING: 'Estacionamiento',
              PHYSICAL_DEMAND: 'Demanda física',
              PICKING_APP: 'Picking en la App',
              PLACE_FOR_LUNCH: 'Lugar para el almuerzo',
              REST_AREA: 'Zona de descanso',
              SCHEDULE_ADHERENCE: 'Cumplimiento de horarios',
              STORAGE: 'Almacenamiento',
              TASK_MATCHES_APP_DESCRIPTION: 'La tarea coincide con la descripción de la aplicación',
              WORKING_HOURS: 'Horas laborales',
              WORK_ENVIRONMENT: 'Entorno de trabajo',
            },
          },
        },
      },
      onboarding: {
        title: 'Datos onboarding',
        subtitle: 'Embudo de registro de Jobbers',
        helper: 'Muestra de {{qty}} Applicants/Jobbers',
        filters: {
          registered: 'Registro',
          activatedEmail: 'Verificación',
          jobber: 'Se convierte en Jobber',
          firstSkill: '1ra Skill ganada',
          firstApplication: '1ra Postulación tarea',
          firstFinishedApplication: 'Primera tarea finalizada',
          firstFinishedApplicationFilter: 'Fecha primera tarea finalizada',
          onboardingPush: 'Correo para impulsar registro',
          clearOne: 'Borrar selección',
          clearAll: 'Limpiar filtros',
        },
        error: {
          title: 'No se logró obtener la información necesaria para mostrar los gráficos',
          subtitle: 'Intente mas tarde',
        },
      },
      operations: {
        title: 'Datos operaciones',
        options: {
          operationalDailyClient: 'Operación diaria de clientes',
          operationalTaskSchedule: 'Operación según horario de tareas',
          operationalShopperSchedule: 'Operación diaria de shopper',
        },
        selectView: 'Selecciona una vista',
        select: 'Selecciona',
        optional: '(opcional)',
        dailyClientOperation: {
          day: 'Dia *',
          placeholder: 'Selecciona un día',
          descriptionTitle: 'Operación diaria de clientes',
          description: 'Revisa la operación diario de todos los cliente, seleccionando un día.',
          table: [
            { title: 'Cliente', empty: 'Todos' },
            { title: 'Sede', empty: 'Todas' },
            { title: 'Tipo de tarea', empty: 'Todos' },
            {
              title: 'Cupos',
              popover: 'Total de cupos requeridos + extra cupos para ese día y cliente.',
            },
            {
              title: 'Postulados',
              popover:
                'Total de postulados para todas las tareas en ese día y cliente en cuestión.',
            },
            {
              title: 'Asignados',
              popover: 'Total de asignados para todas las tareas en ese día y cliente en cuestión.',
            },
            {
              title: 'Presentes',
              popover: 'Total de presentes para todas las tareas en ese día y cliente en cuestión.',
            },
            {
              title: 'Finalizados',
              popover:
                'Total de finalizados para todas las tareas en ese día para el cliente en cuestión.',
            },
            {
              title: 'Cumplimiento',
              popover:
                '100 x (Asignados + Presentes + Finalizados)/Cupos considerando los presentes, finalizados y cupos de ese día y del cliente en cuestión.',
            },
          ],
          tableHourly: [
            { title: 'Horario', popover: 'Horario en que comienza una tarea.' },

            {
              title: 'Cupos',
              popover: 'Total de cupos requeridos + extra cupos para ese día',
            },
            {
              title: 'Postulados',
              popover:
                'Total de postulados para todas las tareas en ese día.',
            },
            {
              title: 'Asignados',
              popover: 'Total de asignados para todas las tareas en ese día.',
            },
            {
              title: 'Presentes',
              popover: 'Total de presentes para todas las tareas en ese día.',
            },
            {
              title: 'Finalizados',
              popover:
                'Total de finalizados para todas las tareas en ese día.',
            },
            {
              title: 'Cumplimiento',
              popover:
                '100 x (Asignados + Presentes + Finalizados)/Cupos considerando los presentes, finalizados y cupos de ese día, en el horario en cuestión.',
            },
          ],
          emptyListMessage: 'Selecciona un día para mostrar resultados',
          noResults: 'No hay resultados para el día seleccionado',
          enterprise: {
            label: 'Cliente',
            placeholder: 'Selecciona',
          },
          store: {
            label: 'Sede',
            placeholder: 'Selecciona',
          },
          jobType: {
            label: 'Tipo de Tarea',
            placeholder: 'Selecciona',
          },
        },
        clientOperationHourly: {
          title: 'Operación según horario de tareas',
          description: 'Revisa todos los horarios que se encuentran en el día seleccionado.',
        },
        shopperOperationHourly: {
          title: 'Operación shopper',
          description: 'Revisa todos los horarios que se encuentran en el día seleccionado.',
          filters: {
            day: {
              label: 'Día',
              placeholder: 'Selecciona un día',
            },
            client: {
              label: 'Cliente',
              placeholder: 'Selecciona',
            },
            store: {
              label: 'Sede',
              placeholder: 'Selecciona',
            }
          },
          columns: {
            hours: {
              title: 'Horario',
              content: '{{startHour}} - {{endHour}} hrs.',
              totalContent: 'Total',
              shopperOrders: {
                qty: '{{shopperOrdersEarly}}/{{shopperOrdersLate}}/{{shopperOrdersOnTime}}/{{shopperOrdersTotal}}',
                tooltip: {
                  early: '{{shopperOrdersEarly}} Adelantados',
                  late: '{{shopperOrdersLate}} Atrasados',
                  onTime: '{{shopperOrdersOnTime}} Ontime',
                  total: '{{shopperOrdersTotal}} Comprometidos',
                }
              }
            },
            quota: 'Cupos',
            applied: 'Postulados',
            assigned: 'Asignados',
            present: 'Presentes',
            absent: 'Ausentes',
            compliance: 'Cumplimiento',
          }
        },
      },
    },
  },
};