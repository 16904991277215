export default {
  applicants: {
    tabs: {
      pendingStaff: {
        title: 'Pendiente Staff',
      },
      pendingAplicants: {
        title: 'Pendiente applicants',
      },
    },
    enums: {
      fileStatus: {
        approved: 'Aprobado',
        rejected: 'Rechazado',
        objected: 'Objetado',
        in_review: 'En revisión',
        pending: 'Pendiente',
      },
    },
    title: 'Pendientes applicant',
    subTitle: 'Acá aparecen los applicants que se encuentran en el proceso de registro.',
    list: {
      columns: {
        id: 'ID',
        creationDate: 'Fecha creación',
        name: '$t(global.name)',
        surname: '$t(global.surname)',
        region: '$t(global.region)',
        commune: '$t(global.commune)',
        staff: 'Staff',
        actions: 'Acciones',
      },
    },
    filters: {
      collapse: {
        basic: 'Filtros Básicos ({{count}} aplicado)',
        basic_plural: 'Filtros Básicos ({{count}} aplicados)',
        document: 'Filtros de Documentos ({{count}} aplicado)',
        document_plural: 'Filtros de Documentos ({{count}} aplicados)',
        skill: 'Filtros de Inducción ({{count}} aplicado)',
        skill_plural: 'Filtros de Inducción ({{count}} aplicados)',
        job: 'Filtros de Tareas ({{count}} aplicado)',
        job_plural: 'Filtros de Tareas ({{count}} aplicados)',
        metric: 'Filtros para Reporte de Métricas con igual tareas ({{count}} aplicado)',
        metric_plural: 'Filtros para Reporte de Métricas con igual tareas ({{count}} aplicados)',
      },
      clear: 'Limpiar filtros',
      vehicle: 'Vehículo',
      staff: 'Staff a cargo',
      campaign: {
        label: 'Campaña',
        title: 'Campaña: {{state}}',
        placeholder: 'Busca por palabra clave',
        tooltip: 'Puedes hacer tu búsqueda por vertical, tarea, región o cliente.',
      },
      isMigrated: {
        title: 'Usuarios: {{state}}',
        label: 'Usuarios',
        false: 'nuevos',
        true: 'migrados',
        none: 'todos',
        falseUpper: 'Nuevos',
        trueUpper: 'Migrados',
        noneUpper: 'Todos',
      },
      createdAt: {
        label: 'Fecha de creación',
      },
      isRejected: {
        title: 'Estado: {{state}}',
        label: 'Estado',
        false: 'no rechazado',
        true: 'rechazado',
        none: 'todos',
        falseUpper: 'No rechazado',
        trueUpper: 'Rechazado',
        noneUpper: 'Todos',
      },
      cellphoneOS: {
        placeholder: 'Dispositivo',
        title: 'Dispositivo: {{state}} ',
      },
      centerCategories: {
        title: 'Categorias completadas: ',
        label: 'Categorias completadas',
        placeholder: 'Categorias',
      },
      skills: {
        placeholder: 'Selecciona',
        title: 'Inducción',
        noSkill: 'Ninguna',
        inProgress: 'En Proceso',
        finalized: 'Finalizadas',
        select: '{{count}} Inducción',
        select_plural: '{{count}} Inducciones',
      },
      blocking: {
        true: 'Bloqueados',
        false: 'No bloqueados',
        title: 'Estado: {{state}}',
      },
      ppe: {
        label: 'Indumentaria',
        title: 'Indumentaria: {{state}}',
        noPpe: 'Ninguno',
      },
      jobberAt: 'Fecha activación',
      lastSkillFinalized: 'Fecha última inducción',
      lastApplicationFinalized: 'Fecha última tarea',
      firstFinishedApplicationAt: 'Fecha primera tarea finalizada',
      stats: {
        title: 'Estadísticas Jobbers',
        placeholder: 'Selecciona',
      },
      telephone: {
        label: 'Teléfono registrado: $t(applicants.filters.telephone.options.{{value}})',
        title: 'Teléfono registrado',
        placeholder: 'Selecciona',
        options: {
          none: 'Ninguno',
          true: 'Si',
          false: 'No',
        },
      },
      verticals: {
        label: 'Verticales de interés: {{value}}',
        title: 'Verticales de interés',
      },
      type: {
        label: 'Tarea de interés: {{value}}',
        title: 'Tareas de interés',
      },
      health_card: {
        label: 'Carnet de sanidad{{value}}',
        placeholder: 'Selecciona',
        options: {
          true: 'Cuenta con carnet',
          false: 'Sin carnet',
        },
      },
      byDocument: {
        label: 'Documento',
        placeholder: 'Selecciona',
        options: {
          all: 'Todos',
          records: 'Antecedentes',
          id_front: 'Cédula de identidad frontal',
          id_reverse: 'Cédula de identidad reverso',
          visa_application_front: 'Trámite de visa (pág. 1)',
          visa_application_reverse: 'Trámite de visa (pág. 2)',
          PEP_front: 'PEP (pág. 1)',
          PEP_reverse: 'PEP (pág. 2)',
          foreign_ID_front: 'Carnet de extranjería frontal',
          foreign_ID_reverse: 'Carnet de extranjería reverso',
          PTP_front: 'PTP frontal',
          PTP_reverse: 'PTP reverso',
          suspensionCertificate: 'Exoneración de retención de 4ta categoría',
          healthCardWithFoodHandling: 'Carnet de sanidad con manipulación de alimentos',
          foodHandlingCertificate: 'Certificado Manipulación de alimentos',
          carDriverLicense: '$t(global.drivingLicence) ($t(global.enums.vehiclesEnum.auto))',
          motorcycleDriverLicense: '$t(global.drivingLicence) ($t(global.enums.vehiclesEnum.moto))',
          mopedDriverLicense: '$t(global.drivingLicence) ($t(global.enums.vehiclesEnum.bicimoto))',
          furgonDriverLicense: '$t(global.drivingLicence) ($t(global.enums.vehiclesEnum.furgon))',
          carRegistrationCertificate: 'Permiso de circulación ($t(global.enums.vehiclesEnum.auto))',
          motorcycleRegistrationCertificate:
            'Permiso de circulación ($t(global.enums.vehiclesEnum.moto))',
          mopedRegistrationCertificate:
            'Permiso de circulación ($t(global.enums.vehiclesEnum.bicimoto))',
          furgonRegistrationCertificate:
            'Permiso de circulación ($t(global.enums.vehiclesEnum.furgon))',
          carInsurance: 'Seguro auto (No SOAP)',
          carSoat:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carSoat) ($t(global.enums.vehiclesEnum.auto))',
          vanSoat:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanSoat) ($t(global.enums.vehiclesEnum.van))',
          motorcycleSoat:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleSoat) ($t(global.enums.vehiclesEnum.moto))',
          motorcycleRoadWorthinessTest:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleRoadWorthinessTest) ($t(global.enums.vehiclesEnum.moto))',
          vanRoadWorthinessTest:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanRoadWorthinessTest) ($t(global.enums.vehiclesEnum.van))',
          carRoadWorthinessTest:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carRoadWorthinessTest) ($t(global.enums.vehiclesEnum.auto))',
        },
      },
      documentStatus: {
        title: 'Estado de documentos',
        label: 'Estado de documentos {{value}}',
        floatLabel: 'Vencimiento del documento',
        placeholder: 'Selecciona',
        options: {
          expired: 'Vencidos',
          toExpire: 'Por vencer',
          woDate: 'Sin fecha ingresada',
        },
      },
      contactType: {
        label: 'Tipo de contacto',
        placeholder: 'Selecciona',
        options: {
          call: 'Llamado',
          message: 'Mensaje',
          email: 'Correo',
        },
      },
      contactStatus: {
        label: 'Estado de contacto',
        placeholder: 'Selecciona',
        options: {
          'no-answer': 'No responde',
          interested: 'Interesado',
          'not-interested': 'No interesado',
          'future-interest': 'Futuro interesado',
          'to-be-recovered': 'A recuperar',
          'not-contacted': 'No contactado',
          closed: 'Cerrado',
        },
      },
      suspensionCertificate: {
        label: 'Doc. exoneración 4ta categoría',
        placeholder: 'Selecciona',
        options: {
          approved: 'Documento aprobado',
          in_review: 'Doc. con revisión pendiente',
          objected: 'Documento objetado',
          pending: 'Sin documento',
          rejected: 'Documento rechazado',
        },
      },
      pendingDocuments: {
        label: 'Documentos por revisar',
        placeholder: 'Selecciona',
      },
      pendingDocumentsStatus: {
        label: 'Estado de documento por revisar',
        placeholder: 'Selecciona',
      },
      onSitesStatus: {
        label: 'Estado de curso presencial',
        placeholder: 'Selecciona',
      },
      onSitesDates: {
        label: 'Fecha de curso presencial',
        placeholder: 'Selecciona',
      },
      onSiteEnterprise: {
        label: 'Cliente curso presencial',
        placeholder: 'Selecciona',
      },
      onSiteStore: {
        label: 'Sede curso presencial',
        placeholder: 'Selecciona',
      },
      lastDocumentUpload: {
        label: 'Fecha de última subida de documento',
      },
      needOfJobbers: {
        label: 'Necesidad de Jobbers',
        placeholder: 'Selecciona',
        tooltip: {
          title: 'Necesidad de Jobbers',
          content: 'Necesidad de Jobbers según lo definido en cada comuna.',
        },
        options: {
          low: 'Baja',
          medium: 'Media',
          high: 'Alta',
        },
      },
      executedAtDocumentsReviewed: {
        label: 'Fecha salida de pendiente staff',
      },
      staffEmailDocumentsReviewed: {
        label: 'Responsable salida de pendiente staff',
        placeholder: 'Busca por correo',
      },
      metrics: {
        finalized: {
          criteria: {
            label: 'Criterio finalización',
            tooltip: {
              title: 'Criterio finalización',
              content:
                'Los jobbers “Finalizados”, serán aquellos que hayan finalizado en tareas que cumplan los filtros de esta fila.',
            },
            placeholder: 'Selecciona',
            options: {
              has: 'Finalizados',
              hasNot: 'No finalizados',
            },
          },
          day: {
            label: 'Fecha de inicio de la tarea',
          },
          jobType: {
            label: 'Tipo de tarea',
            placeholder: 'Selecciona',
          },
          enterprise: {
            label: 'Cliente',
            placeholder: 'Selecciona',
          },
          store: {
            label: 'Sede',
            placeholder: 'Selecciona',
          },
        },
        applies: {
          criteria: {
            label: 'Criterio postulación',
            tooltip: {
              title: 'Criterio postulación',
              content:
                'Los jobbers “Postulados”, serán aquellos que hayan postulado en tareas que cumplan los filtros de esta fila.',
            },
            placeholder: 'Selecciona',
            options: {
              has: 'Postulados',
              hasNot: 'No postulados',
            },
          },
          day: {
            label: 'Fecha de inicio de la tarea',
          },
          jobType: {
            label: 'Tipo de tarea',
            placeholder: 'Selecciona',
          },
          enterprise: {
            label: 'Cliente',
            placeholder: 'Selecciona',
          },
          store: {
            label: 'Sede',
            placeholder: 'Selecciona',
          },
        },
        preCancelation: {
          criteria: {
            label: 'Criterio cancelación',
            tooltip: {
              title: 'Criterio cancelación',
              content:
                'Los jobbers “Cancelados”, serán aquellos que hayan cancelado tareas pre y post asignación que cumplan los filtros de esta fila.',
            },
            placeholder: 'Selecciona',
            options: {
              has: 'Cancelados',
              hasNot: 'No cancelados',
            },
          },
          day: {
            label: 'Fecha de inicio de la tarea',
          },
          jobType: {
            label: 'Tipo de tarea',
            placeholder: 'Selecciona',
          },
          enterprise: {
            label: 'Cliente',
            placeholder: 'Selecciona',
          },
          store: {
            label: 'Sede',
            placeholder: 'Selecciona',
          },
        },
      },
    },
    review: {
      approved: {
        title: 'Aceptar documento',
        subtitle: 'Con esta acción se aprueba el documento del applicant.',

        inputs: {
          comment: 'Comentario de doc. aceptado (opcional)',
        },
        buttons: {
          submit: 'Aceptar',
        },
        alert:
          'Recuerda que al aprobar el documento se enviará un correo al applicant para que continúe con su registro',
      },
      objected: {
        title: 'Objetar documento',
        subtitle: 'Escoge un motivo para objetar el documento',
        inputs: {
          reason: {
            text: 'Motivo de objeción',
            required: 'Ingresa el motivo de la objeción',
          },
          comment: 'Comentario de objeción (opcional)',
        },
        buttons: {
          submit: 'Objetar',
        },
        alert:
          'Recuerda que al objetar el documento se enviará un correo al applicant explicando el motivo y deberá subir nuevamente el documento',
      },
      rejected: {
        title: 'Rechazar documento',
        subtitle: 'Al rechazar el documento el applicant no podrá continuar con su registro',
        inputs: {
          reason: {
            text: 'Motivo de rechazo',
            required: 'Ingresa el motivo del rechazo',
          },
          comment: 'Comentario de rechazo (opcional)',
        },
        buttons: {
          submit: 'Rechazar',
        },
        alert:
          'Recuerda que al rechazar el documento se enviará un correo al applicant indicando que no podrá continuar con su registro, perdiendo el acceso a su cuenta ',
      },
      inputs: {
        evidence: {
          txt: 'Evidencia (opcional)',
          txtOptional: 'Evidencia',
          required: 'Sube la evidencia para aprobar el documento',
          subtitle: 'Adjunta una imagen para que pueda servir de antecedente',
        },
      },
      buttons: {
        cancel: '$t(global.cancel)',
        evidence: 'Cargar imagen',
      },
      upload: {
        genericError: 'Ha ocurrido un error, intentalo nuevamente',
        weightError: 'El archivo excede los 10 Mb',
        formatError: 'Sólo puedes subir archivos en formato {{formats}}',
        maxFileError: 'Solo se admite como máximo {{max}} archivos.',
      },
      noVisualizer: {
        default: [
          'Aquí debes realizar una validación del documento. Utiliza el recurso habitual que tengas disponible (Software, fuente pública, etc.).',
          'Recuerda que dependiendo el resultado obtenido deberás aprobar o rechazar esta sección.',
          'Al ejecutar la acción tendrás que entregar el respaldo dejando opcionalmente un comentario y una evidencia.',
        ],
        frameworkAgreement: [
          'Aquí debes coordinar y llevar a cabo la firma del Acuerdo Marco con el futuro Jobber. Utiliza el recurso habitual que tengas disponible (firma digital, presencial, etc.).',
          'Una vez que esté el documento firmado debes aprobar esta sección.',
          'Al ejecutar la acción tendrás que entregar el respaldo dejando opcionalmente un comentario y una evidencia. Se recomienda dejar como evidencia el documento firmado (jpg, png o pdf) para que quede ingresado en el sistema y poder acceder a él en caso de ser necesario.',
        ],
        vehicles: [
          'El Jobber no ha adjuntado este documento. Puedes hacerlo manualmente en caso de que el Jobber haya hecho entrega de este a través de otros medio como Intercom, whatsapp, etc. ',
          'Aquí	solo podrás aprobar el documento, por ende debes revisarlo bien y que cumpla con los criterios requeridos, si no es así debes pedirlos nuevamente al Jobber, volver a revisar y adjuntarlo. Recuerda que en este caso no puedes objetar ni rechazar el documento.',
          'Al cargar el documento puedes dejar un comentario y quedará registrado el Staff que ejecutó la acción.',
        ],
        otherData: [
          'El Jobber no ha adjuntado este documento. Puedes hacerlo manualmente en caso de que el Jobber haya hecho entrega de este a través de otros medio como Intercom, whatsapp, etc. ',
          'Aquí	solo podrás aprobar el documento, por ende debes revisarlo bien y que cumpla con los criterios requeridos, si no es así debes pedirlos nuevamente al Jobber, volver a revisar y adjuntarlo. Recuerda que en este caso no puedes objetar ni rechazar el documento.',
          'Al cargar el documento puedes dejar un comentario y quedará registrado el Staff que ejecutó la acción.',
        ],
        otherDocuments: [
          'El Jobber no ha adjuntado este documento. Puedes hacerlo manualmente en caso de que el Jobber haya hecho entrega de este a través de otros medio como Intercom, whatsapp, etc. ',
          'Aquí	solo podrás aprobar el documento, por ende debes revisarlo bien y que cumpla con los criterios requeridos, si no es así debes pedirlos nuevamente al Jobber, volver a revisar y adjuntarlo. Recuerda que en este caso no puedes objetar ni rechazar el documento.',
          'Al cargar el documento puedes dejar un comentario y quedará registrado el Staff que ejecutó la acción.',
        ],
      },
      visualizer: {
        actions: { approve: 'Aceptar', object: 'Objetar', reject: 'Rechazar' },
        responsibleStaff: 'Staff responsable: {{staff}}',
        previewJobber: {
          title: 'Información que verá el usuario en su correo:',
          subtitle: {
            rejected: 'Motivo del rechazo',
            approved: 'Motivo del doc. aprobado',
            objected: 'Motivo de objeción',
          },
          comment: {
            rejected: 'Comentario de rechazo',
            approved: 'Comentario de aprobación',
            objected: 'Comentario de objeción',
          },
        },
        rejected: { detail: 'Detalles del rechazo' },
        approved: { detail: 'Detalles del doc. aprobado' },
        objected: { detail: 'Detalles de objeción' },
        objectedTimes: 'N° veces objetado: {{number}}',
        alert: {
          rejected: 'Documento rechazado por staff. Se ha enviado un correo al applicant',
          approved: 'Documento aprobado por staff. Se ha enviado un correo al applicant',
          objected: 'Documento objetado por staff. Se ha enviado un correo al applicant',
          approvedNoEmail: 'Documento aprobado por staff.',
          objectedNoEmail: 'Documento objetado por staff.',
          toExpire:
            'Este documento está próximo a vencer ({{expirationDate}}), por lo que el Jobber debe cargar uno nuevo.',
          expired: {
            isBlocked:
              'Este documento se encuentra vencido ({{expirationDate}}), por lo que debe ser cargado nuevamente por el Jobber.<br/>Jobber no podrá postular o realizar tareas mientras este no sea actualizado y validado.',
            nonBlocking:
              'Este documento se encuentra vencido ({{expirationDate}}), por lo que debe ser cargado nuevamente por el Jobber.<br/>Este documento no impide que el Jobber pueda seguir postulando o realizando tareas.',
          },
        },
        editDate: {
          buttonText: {
            issueDate: 'Editar fecha de emisión',
            expirationDate: 'Editar fecha de vencimiento',
          },
          invalidFormat: 'Ingresa una fecha correcta',
          error: 'Ingresa un formato válido de fecha dd/mm/aaaa',
        },
        records: {
          title: 'Antecedentes',
          issueDate: {
            title: 'Emisión cert. de antecedentes',
            label: 'Fecha de emisión*',
          },
          expiration: {
            CL: {
              records: 'Certificado tiene una validez de 6 meses. Vence el ',
            },
          },
          expirationDate: {
            title: 'Vencimiento de documento',
            label: 'Fecha de vencimiento *',
          },
          alert: {
            error: 'No se logró actualizar la fecha de emisión',
            success: 'Fecha de emisión actualizada.',
          },
        },
        otherDocuments: {
          title: 'Otros documentos',
          expiration: {
            PE: {
              suspensionCertificate: 'Documento tiene validez hasta el ',
            },
            CO: {
              PEP: 'Documento tiene una validez de 2 años. Vence el ',
            },
            CL: {
              visa_application: 'Documento tiene una validez de 6 meses. Vence el ',
            },
          },
          expirationDate: {
            title: 'Vencimiento de documento',
            label: 'Fecha de vencimiento *',
          },
          issueDate: {
            title: 'Emisión de documentos',
            label: 'Fecha de emisión *',
          },
          alert: {
            error: 'No se logró actualizar la fecha de vencimiento',
            success: 'Fecha de vencimiento actualizada.',
          },
          textInfo:
            'Recuerda verificar que la fecha informada por el Jobber, coincida con la fecha indicada en el documento.',
          kindIdentifyDocuments: {
            id: { front: 'Cédula de identidad frontal', reverse: 'Cédula de identidad reverso' },
            PTP: { front: 'PTP frontal', reverse: 'PTP reverso' },
            foreign_ID: {
              front: 'Cédula Extranjería frontal',
              reverse: 'Cédula Extranjería reverso',
            },
            PEP: { front: 'PEP (pág. 1)', reverse: 'PEP (pág. 2)' },
            visa_application: {
              front: 'Trámite de visa definitiva (pág. 1)',
              reverse: 'Trámite de visa definitiva (pág. 2)',
            },
          },
        },
        otherData: {
          title: 'Certificados',
          expirationDate: {
            title: 'Vencimiento de documento',
            label: 'Fecha de vencimiento *',
          },
          issueDate: {
            title: 'Emisión de documentos',
            label: 'Fecha de emisión *',
          },
          alert: {
            error: 'No se logró actualizar la fecha de vencimiento',
            success: 'Fecha de vencimiento actualizada.',
          },
        },
        frameworkAgreement: {
          title: 'Acuerdo marco',
        },
        vehicles: {
          title: 'Vehículos',
          expirationDate: {
            title: 'Vencimiento de documento',
            label: 'Fecha de vencimiento *',
          },
          issueDate: {
            title: 'Emisión de documentos',
            label: 'Fecha de emisión *',
          },
          alert: {
            error: 'No se logró actualizar la fecha de vencimiento',
            success: 'Fecha de vencimiento actualizada.',
          },
        },
        contract: {
          title: 'Contrato',
        },
        save: 'Guardar',
        cancel: 'Cancelar',
        files: {
          identityCardFront: 'Cédula de identidad frontal',
          identityCardReverse: 'Cédula de identidad reverso',
          pensionCertificate: 'Certificado de pensión',
          epsCertificate: 'Certificado de EPS',
          suspensionCertificate: 'Exoneración de retención de 4ta categoría',
          records: 'Antecedentes',
          contract: 'Contrato',
          frameworkAgreement: 'Acuerdo marco',
          carDriverLicense:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carDriverLicense) - $t(global.enums.vehiclesEnum.auto)',
          carOwnershipCard:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carOwnershipCard) - $t(global.enums.vehiclesEnum.auto)',
          carRegistrationCertificate:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carRegistrationCertificate) - $t(global.enums.vehiclesEnum.auto)',
          carRoadWorthinessTest:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carRoadWorthinessTest) - $t(global.enums.vehiclesEnum.auto)',
          carSoat:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carSoat) - $t(global.enums.vehiclesEnum.auto)',
          mopedDriverLicense:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.mopedDriverLicense) - $t(global.enums.vehiclesEnum.bicimoto)',
          mopedOwnershipCard: '',
          mopedRegistrationCertificate:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.mopedRegistrationCertificate) - $t(global.enums.vehiclesEnum.bicimoto)',
          mopedSoat: '',
          motorcycleDriverLicense:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleDriverLicense) - $t(global.enums.vehiclesEnum.moto)',
          motorcycleOwnershipCard:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleOwnershipCard) - $t(global.enums.vehiclesEnum.moto)',
          motorcycleRegistrationCertificate:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleRegistrationCertificate) - $t(global.enums.vehiclesEnum.moto)',
          motorcycleRoadWorthinessTest:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleRoadWorthinessTest) - $t(global.enums.vehiclesEnum.moto)',
          motorcycleSoat:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleSoat) - $t(global.enums.vehiclesEnum.moto)',
          vanDriverLicense:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanDriverLicense) - $t(global.enums.vehiclesEnum.van)',
          vanOwnershipCard:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanOwnershipCard) - $t(global.enums.vehiclesEnum.van)',
          vanRegistrationCertificate: '',
          vanRoadWorthinessTest:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanRoadWorthinessTest) - $t(global.enums.vehiclesEnum.van) ',
          vanSoat:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanSoat) - $t(global.enums.vehiclesEnum.van)',
          vaccinationCard: '$t(global.enums.extraDocumentsMatchEnum.vaccinationCard)',
          foodHandlingCertificate:
            '$t(global.enums.extraDocumentsMatchEnum.foodHandlingCertificate)',
          healthCardWithFoodHandling:
            '$t(global.enums.extraDocumentsMatchEnum.healthCardWithFoodHandling)',
          carInsurance: '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carInsurance)',
          furgonRegistrationCertificate:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.furgonRegistrationCertificate) - $t(global.enums.vehiclesEnum.furgon)',
          furgonDriverLicense:
            '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.furgonDriverLicense) - $t(global.enums.vehiclesEnum.furgon)',
        },
        subStatus: {
          tags: {
            summary: {
              expired: '{{count}} Documento vencido',
              expired_plural: '{{count}} Documentos vencidos',
              toExpire: '{{count}} Documento por vencer',
              toExpire_plural: '{{count}} Documentos por vencer',
              multipleCase: '{{count}} Doc. vencido y {{countTwo}} Doc. por vencer',
              multipleCase_plural: '{{count}} Doc. vencidos y {{countTwo}} Doc. por vencer',
            },
            table: {
              expired: '{{count}} Documento vencido',
              expired_plural: '{{count}} Documentos vencidos',
              toExpire: '{{count}} Documento por vencer',
              toExpire_plural: '{{count}} Documentos por vencer',
              withOutDate: '{{count}} Doc. sin fecha ingresada',
              multipleCase: '{{count}} Doc. vencido - {{countTwo}} por vencer',
              multipleCase_plural: '{{count}} Doc. vencidos - {{countTwo}} por vencer',
              inReview: '{{count}} Doc. por revisar',
              expiredInReview: '{{count}} Doc. por revisar (vencido)',
              expiredInReview_plural: '{{count}} Doc. por revisar (vencidos)',
              toExpiredInReview: '{{count}} Doc. por revisar (por vencer)',
            },
            reducedTag: {
              expired: 'Doc. vencido',
              toExpire: 'Doc. por vencer',
            },
          },
        },
        tooltip: {
          title: {
            table: {
              expired: 'Documento vencido ({{count}})',
              expired_plural: 'Documentos vencidos ({{count}})',
              toExpire: 'Documento por vencer ({{count}})',
              toExpire_plural: 'Documentos por vencer ({{count}})',
              withOutDate: 'Doc. sin fecha ingresada ({{count}})',
              withOutDate_plural: 'Doc. sin fecha ingresada ({{count}})',
              inReview: 'Documento por revisar ({{count}})',
              inReview_plural: 'Documentos por revisar ({{count}})',
            },
            applied: {
              expired: '{{count}} Doc. vencido',
              expired_plural: '{{count}} Doc. vencidos',
              toExpire: '{{count}} Doc. por vencer',
              toExpire_plural: '{{count}} Doc. por vencer',
            },
          },
          files: {
            identityCardFront: 'Cédula de identidad',
            identityCardReverse: 'Cédula de identidad',
            visa_application: 'Cédula de identidad',
            id: 'Cédula de identidad',
            foreign_ID: 'Cédula de identidad',
            ptp: 'Cédula de identidad',
            pep: 'Cédula de identidad',
            ce: 'Cédula de identidad',
            pensionCertificate: 'Certificado de pensión',
            epsCertificate: 'Certificado de EPS',
            suspensionCertificate: 'Exoneración de retención de 4ta categoría',
            records: 'Certificado de antecedentes',
            carDriverLicense:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carDriverLicense) - $t(global.enums.vehiclesEnum.auto)',
            carOwnershipCard:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carOwnershipCard) - $t(global.enums.vehiclesEnum.auto)',
            carRegistrationCertificate:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carRegistrationCertificate) - $t(global.enums.vehiclesEnum.auto)',
            carRoadWorthinessTest:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carRoadWorthinessTest) - $t(global.enums.vehiclesEnum.auto)',
            carSoat:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carSoat) - $t(global.enums.vehiclesEnum.auto)',
            mopedDriverLicense:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.mopedDriverLicense) - $t(global.enums.vehiclesEnum.bicimoto)',
            mopedOwnershipCard: '',
            mopedRegistrationCertificate:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.mopedRegistrationCertificate) - $t(global.enums.vehiclesEnum.bicimoto)',
            mopedSoat:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.mopedSoat) - $t(global.enums.vehiclesEnum.bicimoto)',
            motorcycleDriverLicense:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleDriverLicense) - $t(global.enums.vehiclesEnum.moto)',
            motorcycleOwnershipCard:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleOwnershipCard) - $t(global.enums.vehiclesEnum.moto)',
            motorcycleRegistrationCertificate:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleRegistrationCertificate) - $t(global.enums.vehiclesEnum.moto)',
            motorcycleRoadWorthinessTest:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleRoadWorthinessTest) - $t(global.enums.vehiclesEnum.moto)',
            motorcycleSoat:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.motorcycleSoat) - $t(global.enums.vehiclesEnum.moto)',
            vanDriverLicense:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanDriverLicense) - $t(global.enums.vehiclesEnum.van)',
            vanOwnershipCard:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanOwnershipCard) - $t(global.enums.vehiclesEnum.van)',
            vanRegistrationCertificate:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanRegistrationCertificate) - $t(global.enums.vehiclesEnum.van)',
            vanRoadWorthinessTest:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanRoadWorthinessTest) - $t(global.enums.vehiclesEnum.van) ',
            vanSoat:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.vanSoat) - $t(global.enums.vehiclesEnum.van)',
            vaccinationCard: '$t(global.enums.extraDocumentsMatchEnum.vaccinationCard)',
            foodHandlingCertificate:
              '$t(global.enums.extraDocumentsMatchEnum.foodHandlingCertificate)',
            healthCardWithFoodHandling:
              '$t(global.enums.extraDocumentsMatchEnum.healthCardWithFoodHandling)',
            carInsurance: '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.carInsurance)',
            furgonRegistrationCertificate:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.furgonRegistrationCertificate) - $t(global.enums.vehiclesEnum.furgon)',
            furgonDriverLicense:
              '$t(global.enums.vehicleDocumentKeyCodeMatchEnum.furgonDriverLicense) - $t(global.enums.vehiclesEnum.furgon)',
          },
        },
      },
    },
    summary: {
      blocked: {
        title: 'Usuario bloqueado',
        staff: 'Staff responsable:',
        evidence: {
          notEvidence: 'Sin información',
          title: 'Evidencia {{reason}}',
        },
      },
    },
    jobberDetails: {
      alerts: {
        success: 'La asignación ha cambiado exitosamente',
        error: 'Se ha producido un error, intenta nuevamente',
      },
      header: {
        staff: 'Staff responsable: {{staff}}',
        assignButton: 'Asignar perfil',
        isMigrated: 'Migrado',
      },
      personalInfo: {
        title: 'Datos personales',
        email: '$t(global.email): ',
        address: 'Dirección',
        nationality: 'Nacionalidad',
        telephone: 'Teléfono',
        documentType: 'Tipo de documento',
        cci: 'CCI',
        documentNumber: 'Número de documento',
        sex: 'Género',
        birthdate: 'Día de nacimiento',
        region: '$t(global.region)',
        commune: '$t(global.commune)',
        postalCode: 'Código postal',
        documents: {
          tabs: {
            records: 'Registro',
            additional: 'Información adicional',
          },
          otherData: {
            title: 'Información adicional',
            ppes: 'Elementos de protección personal (EPP)',
            howYouKnowUs: '¿Cómo nos conociste?',
          },
          bankData: {
            bank: 'Banco',
            accountType: {
              label: 'Tipo de cuenta',
              translate: {
                cuenta_vista_rut: 'Cuenta vista / rut',
                corriente: 'Cuenta corriente',
                ahorro: 'Cuenta de ahorros',
              },
            },
            accountNumber: 'Número de cuenta',
            owner: 'Titular de la cuenta',
          },
          vehicles: {
            title: 'Vehículos',
            kind: 'Tipo',
            plate: 'Placa/Patente',
            documents: 'Documentos',
            noVehicle: 'No posee vehiculos',
            isElectric:
              '¿Bicimoto requiere documentación? (NO requiere documentación si es 100% eléctrica y no supera los 0,25 watts de potencia)',
            completed: 'Completado',
            pendingUser: 'Pendiente usuario',
          },
          extraDocuments: {
            title: 'Certificados',
          },
          skills: {
            specificRequirements: 'Requisitos (cuentas, tarjetas, etc)',
            cardTitle: 'Skills (Inducciones)',
            jobberSkills: 'Skills (Inducciones) inscritas por jobber',
            manualSkills: 'Skills (Inducciones) Manual',
            virtuals: {
              courses: {
                eLearning: {
                  noProgress: 'Sin progreso',
                  finalized: 'Finalizado',
                  signedUp: 'Inscrito',
                },
              },
            },
          },
          contracts: {
            cardTitle: 'Anexos y contratos',
            subTitle: 'Contratos y anexos',
            havent: 'El Jobber no tiene un contrato.',
            listPrefix: 'Contrato de',
            list: {
              digital_platforms: {
                'v0.1':
                  'prestación de servicios de trabajador de plataformas digitales independiente',
                'v0.2': 'prestación de servicios',
              },
              joint_account_contract_co: {
                'v1.0': 'cuentas en participación - Acuerdo Marco',
              },
            },
            finish: 'Terminar contrato',
            linkFile: 'contrato.pdf',
            finished: {
              tag: 'Contrato terminado',
              info: 'Por {{staff}}',
              clauses: 'Cláusulas que se incumplieron:',
              reactivate: 'Reactivar contrato',
            },
            reactivate: {
              title: 'Confirmación de reactivación de contrato',
              subtitle:
                'Al reactivar el contrato, el Jobber recibirá un correo informando lo sucedido y su contrato quedará activo.',
              select: {
                label: 'Motivo de reactivación',
                placeholder: 'Selecciona una opción',
                options: {
                  staff_error: 'Me equivoqué',
                  jobber_reevaluation: 'Jobber fue reevaluado',
                },
              },
              cancel: 'Cancelar',
              submit: 'Confirmar reactivación',
              alerts: {
                success: 'Reactivación de contrato con éxito.',
                error: 'No se logró realizar la reactivación',
              },
              tag: 'Contrato reactivado',
              info: 'Por {{staff}}',
              reason: 'Motivo:',
              options: {
                staff_error: 'Me equivoqué',
                jobber_reevaluation: 'Jobber fue reevaluado',
              },
            },
            form: {
              firstTitle: 'Terminar contrato',
              firstSubTitle:
                'Al terminar este contrato, el Jobber será notificado mediante correo inmediatamente.',
              termReason: {
                label: 'Motivo del término',
                required: 'El motivo de término es requerido',
                placeholder: 'Selecciona una opción',
                options: {
                  noncompliance: 'Incumplimiento',
                  store_closing: 'Cierre de sala',
                  internal_company_policies: 'Políticas internas de la empresa',
                },
              },
              clauses: {
                label: 'Cláusulas',
                placeholder: 'Busca por número o letra de la cláusula',
                selected: 'Cláusulas seleccionadas',
              },
              termDate: {
                label: 'Fecha de término del contrato',
                required: 'La fecha es requerida',
                future: '30 días a contar de hoy',
              },
              secondTitle: 'Detalles del motivo',
              secondSubTitle:
                'Menciona el hecho que gatilló el término del contrato, con su fecha y hora aproximada.',
              reason: {
                label: 'Motivo',
                placeholder: 'Ingresa los hechos que sucedieron.',
                required: 'El motivo es requerido',
              },
              date: {
                label: 'Fecha',
                required: 'La fecha es requerida',
              },
              hour: {
                label: 'Hora aprox.',
                required: 'La hora es requerida',
              },
              enterprise: {
                label: 'Cliente comercial',
              },
              store: {
                label: 'Sala(s)',
                selected: 'Selección de sala(s)',
                placeholder: 'Buscar salas',
              },
              resign: {
                label: 'No permitir que el Jobber firme este contrato nuevamente.',
              },
              cancel: 'Cancelar',
              submit: 'Terminar contrato',
              alerts: {
                noncompliance:
                  'En caso de incumplimiento, la fecha de término del contrato será el mismo día del cierre (hoy).',
                store_closing:
                  'Si la firma del Contrato tiene <b>menos de 6 meses</b>, la fecha de término debe ser de al menos un par de días.<br/>Si la firma del Contrato tiene <b>6 meses o más</b>, la fecha de término debe ser -al menos- <b>30 días.</b>',
                internal_company_policies:
                  'Si la firma del Contrato tiene <b>menos de 6 meses</b>, la fecha de término debe ser de al menos un par de días.<br/>Si la firma del Contrato tiene <b>6 meses o más</b>, la fecha de término debe ser -al menos- <b>30 días.</b>',
                reason_room_close:
                  'Este motivo sólo aplica si no existen otras salas cercanas al Jobber, donde pueda seguir haciendo tareas de última milla bajo este Contrato.',
              },
            },
            modal: {
              title: 'Confirmación de término de contrato',
              subtitle: 'Revisa los datos antes de confirmar el término del contrato.',
              contract: 'Contrato',
              termDate: 'Fecha de término del contrato',
              termReason: 'Motivo',
              canResign: 'Jobber podrá firmar nuevamente',
              reason: 'Detalle del motivo',
              date: 'Fecha',
              stores: 'Sala(s)',
              hour: 'Hora aproximada',
              cancel: 'Cancelar',
              submit: 'Confirmar término',
              alerts: {
                notResign:
                  '* Al ser un incumplimiento de Contrato, el Jobber no podrá firmar un Contrato de este tipo nuevamente.',
                notify:
                  'Al confirmar, el Jobber será notificado inmediatamente del término mediante un correo.',
                success: 'Término de contrato realizado con éxito.',
                error: 'No se logró ejecutar el término del contrato',
              },
            },
          },
          ruc: {
            title: 'RUC',
            user: 'Usuario',
            pass: 'Clave',
            suspension: 'Código de exoneración de retención de 4ta categoría: ',
          },
        },
        rappiId: {
          text: 'Rappi Id',
          modal: {
            title: 'Editar Rappi Id',
            description: 'Edita el Rappi Id del jobber',
            inputLabel: 'Rappi Id *',
            buttonOk: 'Guardar Datos',
            buttonCancel: 'Cancelar',
            alert: 'Procura que el Rappi Id ingresado no esté asociado a otro Jobber.',
            successAlert: 'Rappi Id actualizado con éxito.',
          },
        },
        jobberCoins: 'Jobber Coins: {{quantity}}',
      },
      speaksSpanish: {
        title: '¿El jobber habla español?',
        callToAction: 'Comunícate con el Jobber',
        options: {
          true: '$t(global.yes)',
          false: '$t(global.no)',
        },
      },
      vehiclesInfo: {
        title: 'Datos vehículo',
        notVehicles: 'No posee vehículo',
      },
      verticals: {
        title: '<strong>Verticales de interés: </strong> {{verticals}}',
      },
      operativeSystem: {
        title: '<strong>Sistema operativo: </strong> {{types}}',
        notData: 'No hay datos',
        android: 'Android',
        ios: 'iOs',
      },
      jobsCompleted: {
        first: 'Primera tarea',
        jocCode: ' - ID {{code}}',
        last: 'Última tarea',
      },
      logbook: {
        title: 'Bitácora',
        buttons: {
          addComment: 'Agregar comentario',
          seeMore: '+ Ver más',
        },
        tabs: {
          jobber_activity: 'Actividad Jobber',
          staff_record: 'Registro Staff',
        },
        jobberActivities: {
          new: 'Nuevo',
          old: 'Anterior',
          newDoc: 'Doc. actual',
          oldDoc: 'Doc. anterior',
          more: '+ Ver más',
          less: '- Ver menos',
          byJobber: 'Actualiza Jobber',
          byStaff: 'Staff {{fullname}}',
          byReset: 'Reseteo sistema',
          resetedCode: 'Código reseteado:',
          fields: {
            personal_data: 'datos personales',
            banking_data: 'datos bancarios',
            other_data: 'otros datos',
            email: 'correo',
          },
          keys: {
            names: 'Nombre(s)',
            surnames: 'Apellidos',
            dateOfBirth: 'Fecha de nacimiento',
            bank: 'Banco',
            accountNumber: 'Número de cuenta',
            address: 'Dirección',
            gender: 'Género',
            telephone: 'Teléfono',
            accountType: 'Tipo de cuenta',
            documentNumber: 'Número de documento',
            email: 'Correo',
            cityName: 'Ciudad',
            localityName: 'Localidad',
            regionName: 'Región',
            street: 'Calle',
            streetNumber: 'Número de calle',
            interbankCode: 'CCI',
            department: 'Departamento',
            ruc: 'RUC',
            user: 'Usuario SUNAT',
            password: 'Clave Sol',
            suspension: 'Código de exoneración de 4ta categoría',
            originCountry: 'Nacionalidad',
            documentType: 'Tipo de documento',
            empty: 'Sin registros',
          },
        },
        item: {
          defaultText: '',
          type: {
            interested: 'interesado',
            'not-interested': 'no interesado',
            'future-interest': 'futuro interesado',
            'no-answer': 'no responde',
            assign: 'Staff',
            'personal-data': 'datos personales',
            'to-recover': 'a recuperar',
            closed: 'Cerrado',
          },
          action: {
            call: 'Llamado',
            email: '$t(global.post)',
            message: 'Mensaje',
            update: 'Edición',
            assignation: 'Asignación nuevo',
          },
        },
        addComment: {
          title: 'Agregar un comentario',
          subtitle: {
            jobber:
              'Aquí puedes dejar registro de llamadas, correos y mensajes enviados al Jobber.',
            applicant:
              'Aquí puedes dejar registro de llamadas, correos y mensajes enviados al Applicant.',
          },
          submit: 'Agregar comentario',
          inputs: {
            category: {
              text: 'Nombre del comentario',
              placeholder: 'Selecciona',
              required: 'Ingresa el nombre del comentario',
            },
            categoryType: {
              text: 'Detalle del comentario',
              placeholder: 'Selecciona',
              required: 'Ingresa el detalle del comentario',
            },
            comment: {
              text: 'Escribe tu comentario (opcional)',
              placeholder: 'Ingresa el motivo ',
            },
          },
          type: {
            interested: 'Interesado',
            'not-interested': 'No interesado',
            'future-interest': 'Futuro interesado',
            'no-answer': 'No responde',
            closed: 'Cerrado',
          },
        },
      },
      assign: {
        title: 'Asignarme este perfil',
        submit: 'Asignar este perfil',
        alert: [
          'Recuerda que al asignarte este perfil quedará a tu nombre',
          ' y {{staff}} ya no lo tendrá dentro de sus asignaciones',
        ],
        comment: {
          text: 'Comentario sobre el cambio de asignación (opcional)',
          placeholder: 'Ingresa el motivo',
        },
      },
      editUser: {
        title: 'Editar datos personales',
        subtitle: 'Selecciona el o los datos que quieras editar.',
        alert:
          'Recuerda que al editar los datos personales estos se reflejarán en la tabla y puedes editarlos las veces necesarias',
        okText: 'Guardar datos',
        cancelText: 'Salir sin guardar',
        inputs: {
          email: {
            type: 'Ingresa un email con formato correcto',
            required: 'Ingresa un email',
          },
          name: {
            required: 'Ingresa el nombre',
            pattern: 'Ingresa sólo letras y carácteres válidos',
          },
          surname: {
            required: 'Ingresa el apellido',
            pattern: 'Ingresa sólo letras y carácteres válidos',
          },
          documentNumber: {
            title: 'Número de documento',
            lower: '$t(applicants.jobberDetails.editUser.inputs.documentNumber.title, lowercase)',
          },
          docSel: { title: 'Tipo de documento' },
        },
        success: 'Datos editados exitosamente',
        email409:
          'El email ingresado ya existe en otro usuario, gestionar solución directamente con el equipo de soporte.',
        docNum409:
          'El número de documento ingresado ya existe en otro usuario, gestionar solución directamente con el equipo de soporte.',
        genericError: 'Ha ocurrido un error inesperado, recarga la página e intenta nuevamente',
      },
      deleteUser: {
        action: 'Eliminar cuenta',
        title: 'Eliminar cuenta applicant',
        subtitle: 'Al eliminar al applicant ',
        subtitleExtra: ', está será eliminada permanentemente y no podrá ser recuperada.',
        success: 'Applicant eliminado con éxito.',
      },
      softDelete: {
        action: 'Eliminar cuenta',
        title: 'Eliminar cuenta jobber',
        subtitle: 'Al eliminar al jobber ',
        subtitleExtra: ', está será eliminada permanentemente y no podrá ser recuperada.',
        success: 'Jobber eliminado con éxito.',
      },
      assistanceMetricsPercentage: {
        title: 'Asistencia',
        percentage: '{{percentage}}%',
        percentageDefault: '-',
        description: 'Porcentaje obtenido de tareas finalizadas ÷ (finalizadas + ausentes)',
      },
    },
    bulkAssignment: {
      open: 'Asignarme este perfil',
      title: 'Asignarme Applicant',
      title_plural: 'Asignarme Applicants',
      subtitle: 'Estás a punto de asignar el siguiente applicant',
      subtitle_plural: 'Estás a punto de asignar los siguientes applicants',
      submit: 'Asignar perfil',
      submit_plural: 'Asignar perfiles',
      popoverTitle: 'Staff a cargo',
      selected: {
        hint: 'Para ver el staff a cargo del applicant, pon el cursor sobre su nombre',
        hint_plural: 'Para ver el staff a cargo de cada applicant, pon el cursor sobre su nombre',
        info: 'Staff a cargo actualmente del applicant seleccionado:',
        info_plural: 'Staff a cargo actualmente de los applicants seleccionados:',
      },
      alert:
        'Recuerda que al asignarte el perfil, quedará a tu nombre y el staff a cargo ya no lo tendrá dentro de sus asignaciones.',
      alert_plural:
        'Recuerda que al asignarte los perfiles, quedarán a tu nombre y el staff a cargo ya no los tendrá dentro de sus asignaciones.',
      comment: {
        text: 'Comentario sobre el cambio de asignación (opcional)',
        placeholder: 'Ingresa el motivo',
      },
      success: 'Applicant asignado exitosamente',
      success_plural: 'Applicants asignados exitosamente',
    },
    inputSearch: {
      title: 'Buscar por correo, nro. documento, nombre, apellido o teléfono.',
      placeholder: 'Buscar por correo, nro. documento, nombre, apellido o teléfono.',
      alert: {
        message: 'Carácter inválido.',
      },
      description: 'Datos actuales del Jobber',
      searchOld: 'Buscar por datos anteriores del Jobber',
      close: 'Cerrar',
    },
    inputSearchHistory: {
      title:
        'Buscar por histórico de número de teléfono, correo, número de documento o número de cuenta bancaria',
      placeholder:
        'Buscar por histórico de número de teléfono, correo, número de documento o número de cuenta bancaria',
      description: 'Datos anteriores del Jobber',
      close: 'Cerrar',
    },
  },
  jobbers: {
    title: 'Jobbers activos',
    subTitle: 'Acá aparecen los jobbers activos.',
    subTitlePending: 'Acá aparecen los jobbers activos con datos pendientes por parte del staff.',
    getJobbersReport: {
      button: 'Descargar reporte de Jobbers',
      buttonApplicants: 'Descargar reporte de Applicants',
      success: 'Reporte enviado a su correo con éxito.',
      error: 'No se pudo descargar el reporte. Código: {{code}}',
    },
    getMetrics: {
      button: 'Reporte de Métricas',
      success: 'Reporte enviado a su correo con éxito.',
      error: 'No se pudo descargar el reporte. Código: {{code}}',
    },
    tabs: {
      jobbers: {
        title: 'Jobbers',
      },
      documentStaff: {
        title: 'Pendiente Staff',
      },
    },
    list: {
      columns: {
        email: 'Correo',
        jobber: {
          title: 'Jobber',
          tag: {
            toExpire: 'Doc. vence {{date}}',
            expired: 'Doc. venció {{date}}',
          },
        },
        location: '$t(global.region) / $t(global.commune)',
        dates: {
          title: 'Fechas',
          tags: {
            lastJob: 'Fecha última tarea',
            lastSkill: 'Fecha última inducción',
            jobberAt: 'Fecha de activación',
          },
        },
        stats: {
          title: 'Estadísticas',
          tags: {
            assigned: 'Application asignadas (Siguientes 7 días)',
            applied: 'Application postuladas (Siguientes 7 días)',
            finished: 'Application finalizadas (Últimos 30 días)',
            absent: 'Application ausentes (Últimos 30 días)',
            cancelled: 'Application canceladas post asignación (Últimos 30 días)',
          },
        },
        status: {
          title: 'Estados',
          isMigrated: 'Migrado',
          tags: {
            jobber: {
              type: 'default',
              title: 'Perfil: Básico',
              tooltip: 'Perfil solo con información del registro inicial.',
            },
            jobber_intermediate: {
              type: 'green',
              title: 'Perfil: Intermedio',
              tooltip:
                'Perfil con preferencias del Jobber (ingresos, disponibilidad, áreas de interés y exp. laboral).',
            },
            jobber_advance: {
              type: 'bluegeek',
              title: 'Perfil: Avanzado',
              tooltip:
                'Perfil con preferencias del Jobber y datos adicionales (resumen, CV,  educación, etc.).',
            },
          },
          withoutTasks: {
            low: {
              type: 'green',
              title: 'Sin tareas en {{days}} días',
              tooltip: 'Jobber sin tareas realizadas dentro de {{days}} días.',
            },
            half: {
              type: 'yellow',
              title: 'Sin tareas en más de {{days}} días',
              tooltip: 'Jobber sin tareas realizadas en más de {{days}} días.',
            },
            high: {
              type: 'red',
              title: 'Sin tareas en más de {{days}} días',
              tooltip: 'Jobber sin tareas realizadas en más de {{days}} días.',
            },
            undefined: {
              type: 'default',
              title: 'Sin información',
              tooltip: 'Sin información',
            },
          },
        },
      },
    },
  },
  jobberRequests: {
    title: 'Solicitudes',
    subtitle: 'Revisa las solicitudes de eliminación de cuenta enviadas por los Jobbers.',
    filters: {
      filterSearch: { title: 'Buscar', placeholder: 'Buscar Nro de solicitud o Jobber' },
      filterClear: { title: 'Limpiar filtros' },
      filterDatePicker: { title: 'Fecha de solicitud' },
      status: { title: 'Estado', placeholder: 'Selecciona' },
    },
    table: {
      columns: {
        request: 'ID Solicitud',
        jobber: 'Jobber',
        date: 'Fecha solicitud',
        status: 'Estado',
        category: 'Solicitud',
        actions: 'Acciones',
      },
      actions: {
        close_request: 'Cerrar ticket',
      },
    },
    categories: {
      delete_account: 'Eliminar cuenta',
    },
    categoriesStatus: {
      pending: { color: 'yellow', label: 'Pendiente' },
      finished: { color: 'green', label: 'Finalizada' },
    },
    close_request: {
      title: 'Cerrar ticket',
      subtitle:
        'Para cerrar el ticket del Jobber <b>{{jobber}}</b>, primero ingresa un comentario que indique la gestión del cierre.',
      cancel: 'Cancelar',
      submit: 'Cerrar ticket',
      comment: {
        label: 'Comentario',
        placeholder: 'Ingresa un comentario que indique la gestión del ticket.',
        error: 'El comentario es requerido.',
        length: 'La cantidad de caracteres debe estar entre 20 y 300.',
      },
      alerts: {
        success: 'El ticket del Jobber {{jobber}}, fue cerrado exitosamente.',
        error: 'No se logró cerrar el ticket.',
      },
    },
  },
};
