export default {
  payments: {
    shoppers: {
      title: 'Pagos Shoppers',
      subtitle: 'Revisa las tareas por pagar y las liquidaciones de pagos.',
      createManual: {
        title: 'Crear orden de pago manual Shopper',
        subtitle:
          'Selecciona el o los Jobbers a los que le crearás la orden manual y luego selecciona el tipo.',
        form: {
          code: { label: 'N° pedido *', placeholder: 'Ingresa el SG' },
          order: {
            jobber: 'Jobber: ',
            rol: 'Rol: ',
          },
          type: {
            label: 'Tipo de orden',
            options: {
              payment: 'Pago',
              discount: 'Cobro',
            },
          },
          typePayment: {
            label: 'Tipo de {{type}} *',
            required: 'El tipo de {{type}} es requerido',
            options: {
              kms_difference: 'Ajuste diferencia KMS',
              sku_difference: 'Ajuste diferencia SKU',
              units_difference: 'Ajuste diferencia unidades',
              secure_difference: 'Ajuste diferencia asegurado',
              incentive_difference: 'Ajuste diferencia incentivo',
              extra_payment: 'Pago extra especial',
            },
            info: 'Tipo de {{type}} que verá el Jobber en la App.',
          },
          additionalExpenses: {
            label: 'Pago extra por *',
            options: {
              toll: 'Peaje',
              parking: 'Estacionamiento',
              other: 'Otro',
            },
          },
          additionalExpensesComment: {
            label: 'Otro *',
            placeholder: 'Ingresa el motivo del pago extra',
            info: 'Motivo se mostrará al Jobber en la App.',
            error: 'Este campo es obligatorio.',
          },
          amount: {
            label: 'Monto a {{type}} *',
            required: 'El monto a {{type}} es requerido',
            helper: 'Ingresa un valor entre $0 y $200000',
            minmax: 'Ingresa un valor entre {{range}}',
          },
          dueDate: {
            label: 'Fecha de vencimiento *',
            required: 'La fecha de vencimiento es requerida.',
          },
          qty: {
            label: 'Cantidad de {{type}}',
            required: 'La cantidad de {{type}} es requerida.',
            helper: 'Ingresa un valor entre {{range}}.',
            minmax: 'Ingresa un valor entre {{range}}.',
          },
          clientQty: {
            label: 'Cargar cobro de cantidad al cliente',
            helper: 'Cobros extras asociados se mostrarán en la prefactura de Shopper.',
          },
          clientAditionalExpenses: {
            label: 'Cargar cobro de pago extra especial al cliente',
            helper: 'Cobros extras asociados se mostrarán en la prefactura de Shopper.',
          },
          comment: {
            label: 'Comentario (opcional)',
            placeholder: 'Ingresa los detalles del motivo.',
          },
          cancel: 'Cancelar',
          submit: 'Crear orden manual',
        },
        alerts: {
          empty: 'El código ingresado no existe. Revisa que esté bien escrito o ingresa otro.',
          isntFinished:
            'El pedido ingresado no está Finalizado. Ingresa uno con ese estado para continuar.',
          success: 'Orden de pago manual creada con éxito.',
          error: 'No se logro crear la orden de pago manual.',
        },
      },
      massive: {
        title: 'Crear order de pago masiva Shopper',
        subtitle: 'Carga la plantilla con los Jobbers. Si no la tienes, descárgala a continuación.',
        template: {
          label: 'Descargar plantilla base',
          url: {
            develop: `https://tj-public-assets-dev.s3.amazonaws.com/massive-load-money-order-shopper.csv?${new Date().getTime()}`,
            staging: `https://tj-public-assets-stg.s3.amazonaws.com/massive-load-money-order-shopper.csv?${new Date().getTime()}`,
            production: `https://tj-public-assets.s3.amazonaws.com/massive-load-money-order-shopper.csv?${new Date().getTime()}`,
          },
        },
        file: {
          title: 'Orden de pago masiva',
          filename: 'plantilla base carga masiva',
          button: 'Cargar plantilla',
        },
        alerts: {
          success:
            'Orden de pago cargada con éxito. Hemos enviado un correo con los resultados de la misma.',
          error: 'Ocurrió un error al realizar la carga. Intenta nuevamente.',
          info: 'Recuerda que el motivo debe ser agnóstico a mayúsculas y tanto el código de tarea y comentario son opcionales.',
        },
        cancel: 'Cancelar',
        submit: 'Crear orden masiva',
      },
    },
    labours: {
      title: 'Pagos Labours',
      subtitle: 'Revisa las tareas por pagar y las liquidaciones de pagos.',
    },
    page: {
      title: 'Pagos',
      subtitle: 'Revisa las tareas por pagar y las liquidaciones de pagos.',
      moneyOrderTab: 'Órdenes de pago',
      settlementTab: 'Liquidaciones',
      processesTab: 'Procesos de liquidación',
      incentivesTab: 'Procesos de incentivos',
      button: 'Generar proceso de liquidación',
      incentiveButton: 'Ejecutar asegurados SMU',
      modalActions: {
        blockMoneyOrder: {
          title: 'Bloquear pago',
          okText: 'Bloquear pago',
          text: 'Al bloquear el pago del Jobber <strong>{{jobber}}</strong>, este quedará detenido y no podrá pasar a estado liquidado. ',
          textArea: {
            placeholder: 'Ingresa el detalle del bloqueo.',
            label: 'Observación (opcional)',
            maxLength: '{{count}} / {{maxLength}} caracteres',
          },
        },
        unblockMoneyOrder: {
          title: 'Desbloquear pago',
          okText: 'Desbloquear pago',
          text: 'Al desbloquear el pago del Jobber <strong>{{jobber}}</strong>, este pasará a estado “Por pagar”.',
        },
      },
    },
    moneyOrderListShopper: {
      subtitle: 'Revisa el listado de órdenes de pago.',
      downloadButton: 'Descargar órdenes filtradas',
      downloadApplicationReport: 'Reporte de Applications filtradas',
      newButton: 'Crear orden de pago',
      modal: {
        title: 'Descarga de órdenes filtradas',
        info: {
          is_valid: 'Al confirmar, se enviará <strong>{{count}} resultado</strong> a tu correo.',
          is_valid_plural:
            'Al confirmar, se enviarán <strong>{{count}} resultados</strong> a tu correo.',
          invalid:
            'Para realizar esta acción, debes aplicar al menos el filtro de <strong>"Fecha finalizacion pedido".</strong>',
        },
        button: {
          is_valid: 'Confirmar descarga',
          invalid: 'Entendido',
        },
        report: {},
        enterprise: 'Cliente',
        store: 'Sede',
        finishedDate: 'Fecha finalización pedido',
      },
      alerts: {
        success: 'La descarga de órdenes de pago, ha sido enviada con éxito a tu correo.',
        error: 'Ocurrió un error al descargar el archivo, inténtelo de nuevo',
        downloadApplicationReport: 'Reporte de applications generado con éxito.',
      },
      filters: {
        select: 'Seleccione',
        client: 'Cliente',
        stores: 'Sede',
        jobType: 'Tipo pedido',
        date: 'Fecha ejecución pedido',
        finish: 'Fecha finalización pedido',
        status: 'Estado',
      },
      filterSearch: { title: 'Buscar', placeholder: 'Buscar Jobber, RUT o ID OP' },
      filterExtraSearch: { title: 'Buscar por código pedido', placeholder: 'Ingresa el SG' },
      filterDatePicker: { title: 'Fecha vencimiento' },
      filterClear: { title: 'Limpiar filtros' },
      actionButtons: {
        massive: 'Orden masiva',
        manual: 'Orden manual',
      },
      columns: {
        code: 'ID OP',
        jobber: 'Jobber',
        soCode: 'N° Pedido',
        enterprise: 'Sede/Cliente',
        sodueDate: 'Fecha pedido',
        total: 'Monto',
        dueDate: 'Vencimiento',
        status: 'Estado',
        actions: 'Acciones',
      },
      actions: {
        block: 'Bloquear pago',
        unblock: 'Desbloquear pago',
      },
      statusUpdate: {
        blocked: 'Pago bloqueado con éxito.',
        payable: 'Pago desbloqueado con éxito.',
      },
      massiveStatus: {
        success: 'Orden de pago masiva creada con éxito. ',
        error: 'Ocurrió un error al realizar la carga de pago masiva. Intenta nuevamente.',
      },
    },
    moneyOrderList: {
      subtitle: 'Revisa el listado de órdenes de pago.',
      downloadButton: 'Descargar órdenes filtradas',
      newButton: 'Crear orden de pago',
      modal: {
        title: 'Descarga de órdenes filtradas',
        info: {
          is_valid: 'Al confirmar, se enviará <strong>{{count}} resultado</strong> a tu correo.',
          is_valid_plural:
            'Al confirmar, se enviarán <strong>{{count}} resultados</strong> a tu correo.',
          invalid:
            'Para realizar esta acción, debes aplicar al menos el filtro de <strong>"Fecha ejecución tarea".</strong>',
        },
        button: {
          is_valid: 'Confirmar descarga',
          invalid: 'Entendido',
        },
        report: {},
        enterprise: 'Cliente',
        store: 'Sede',
        startDate: 'Fecha ejecución tarea',
      },
      actionButtons: {
        massive: 'Orden masiva',
        manual: 'Orden manual',
      },
      filters: {
        select: 'Seleccione',
        client: 'Cliente',
        stores: 'Sede',
        jobType: 'Tipo tarea',
        date: 'Fecha ejecución tarea',
        finish: 'Fecha finalización tarea',
        status: 'Estado',
      },
      filterSearch: { title: 'Buscar', placeholder: 'Buscar Jobber, RUT o ID OP' },
      filterClear: { title: 'Limpiar filtros' },
      filterDatePicker: { title: 'Fecha vencimiento' },
      columns: {
        code: 'ID OP',
        jobber: 'Jobber',
        jobId: 'ID Tarea',
        jobType: 'Tipo de tarea',
        jobDate: 'Ejecución Tarea',
        total: 'Monto',
        dueDate: 'Vencimiento',
        status: 'Estado',
        actions: 'Acciones',
      },
      actions: {
        block: 'Bloquear pago',
        unblock: 'Desbloquear pago',
      },
      statusUpdate: {
        blocked: 'Pago bloqueado con éxito.',
        payable: 'Pago desbloqueado con éxito.',
      },
      massiveStatus: {
        success: 'Orden de pago masiva creada con éxito. ',
        error: 'Ocurrió un error al realizar la carga de pago masiva. Intenta nuevamente.',
      },
    },
    settlementList: {
      subtitle: 'Revisa los próximos pagos.',
      downloadReport: 'Reporte de liquidaciones filtradas',
      button: 'Cargar nómina de pagos realizados',
      loadSettlementPaymentsSuccess:
        'Carga de nóminas de pagos realizada con éxito, revise su correo para verificar los cambios realizados',
      downloadSettlementsFileSuccess:
        'Envío de información bancaria realizado con éxito, revise su correo para ver el reporte',
      filters: {
        select: 'Seleccione',
        status: 'Estado',
        dateFrom: 'Fecha inicial de corte',
        dateTo: 'Fecha final de corte',
      },
      filterSearch: { title: 'Buscar', placeholder: 'Buscar Jobber, RUT o ID liquidación' },
      filterClear: { title: 'Limpiar filtros' },
      filterDatePicker: { title: 'Fecha de pago efectiva' },
      columns: {
        code: 'ID Liquidación',
        jobber: 'Jobber',
        jobberDocument: 'N° documento',
        total: {
          title: 'Monto a pagar',
          popover: 'Es la suma de documentos en estado "por pagar".',
        },
        cutoffAtFrom: 'Fecha inicial corte',
        cutoffAtTo: 'Fecha final corte',
        date: 'Fecha de pago efectiva',
        status: 'Estado',
        actions: 'Acciones',
      },
      table: {
        actions: {
          delete: 'Eliminar liquidación',
        },
      },
      modal: {
        delete: {
          title: 'Eliminar liquidación',
          message: `Al eliminar la liquidación {{code}}, los documentos que la componen pasarán a estado “Por
          pagar”.`,
        },
        download: {
          title: 'Reporte de liquidaciones filtradas',
          subTitle: {
            authorized:
              'Al confirmar, enviaremos a tu correo dos reportes, uno con las liquidaciones filtradas y otro con las órdenes de pago asociadas.',
            unauthorized:
              'Para realizar esta acción, debes aplicar al menos el filtro de <strong>"Fecha final de corte".</strong>',
          },
          label: {
            status: 'Estado',
            dueDate: 'Fecha final de corte',
          },
          buttons: {
            authorized: 'Generar reporte',
            unauthorized: 'Entendido',
          },
          alert: {
            success: 'Reporte de liquidaciones filtradas, enviado con éxito.',
            error: 'Ocurrió un error al descargar el archivo, inténtelo de nuevo',
          },
          status: {
            payable: 'Por pagar',
            paid_out: 'Pagado',
            reprocess: 'En reproceso',
          },
        },
      },
      alerts: {
        delete: {
          success: `Liquidación {{code}}, eliminada con éxito`,
        },
      },
      uploadModal: {
        title: 'Carga de nómina de pagos realizados',
        subtitle: 'El resultado de la carga de la nómina, será enviada a tu correo.',
        alert: 'Al momento de completar la nómina recuerda: ',
        alertLines: [
          'Utilizar siempre la plantilla base de excel actualizada.',
          'Los estados deben ir en MAYÚSCULA.',
          'Ingresar las fechas de pago con el formato dd-mm-aaaa hora (ej: 01-07-2022 09:00). ',
        ],
        okText: 'Cargar nómina',
        file: {
          title: 'Nómina de pagos',
          button: 'Cargar nómina',
          download: 'Descargar plantilla base',
          downloadLink: {
            develop: `https://tj-public-assets-dev.s3.amazonaws.com/root/payment-load/Template+carga+pagos.csv?${new Date().getTime()}`,
            staging: `https://tj-public-assets-stg.s3.amazonaws.com/root/payment-load/Template+carga+pagos.csv?${new Date().getTime()}`,
            production: `https://tj-public-assets.s3.amazonaws.com/root/payment-load/Template+carga+pagos.csv?${new Date().getTime()}`,
          },
          shopperDownloadLink: {
            develop: `https://tj-public-assets-dev.s3.amazonaws.com/Template%2Bcarga%2Bpagos.csv?${new Date().getTime()}`,
            staging: `https://tj-public-assets-stg.s3.amazonaws.com/Template%2Bcarga%2Bpagos.csv?${new Date().getTime()}`,
            production: `https://tj-public-assets.s3.amazonaws.com/Template%2Bcarga%2Bpagos.csv?${new Date().getTime()}`,
          },
        },
      },
    },
    incentives: {
      filters: {
        filterSearch: { title: 'Buscar', placeholder: 'Buscar ID preceso' },
        filterClear: { title: 'Limpiar filtros' },
        filterDatePicker: { title: 'Fecha de creación' },
      },
      columns: {
        id: { title: 'ID proceso' },
        responsible: { title: 'Responsable' },
        execution: { title: 'Fecha de creación' },
        takes: { title: 'Fechas tomadas' },
        quantity: {
          title: 'N° de órdenes',
          info: '{{count}} órden creada',
          info_plural: '{{count}} órdenes creadas',
        },
        affected: {
          title: 'App. afectadas',
          info: '{{count}} Application afectada',
          info_plural: '{{count}} Applications afectadas',
        },
        actions: {
          title: 'Acciones',
          options: {
            undo: 'Deshacer proceso de incentivo',
          },
        },
      },
      undo: {
        title: 'Deshacer proceso de incentivo',
        subtitle: 'ID proceso {{code}}',
        beforeContent:
          'Al confirmar, todas las órdenes de pago creadas en este proceso serán eliminadas, al igual que el proceso ejecutado.',
        afterContent:
          'No es posible deshacer el proceso, ya que las siguientes órdenes de pago <strong>({{qty}})</strong> se encuentran en estados que no lo permiten.',
        item: 'ID {{code}} (Pagada)',
        cancel: 'Cancelar',
        submit: 'Deshacer proceso de incentivo',
        ok: 'Entendido',
        alerts: {
          delete: {
            success:
              'El proceso de <strong>incentivo {{code}},</strong> ha sido eliminado con éxito.',
          },
        },
        moneyOrderStatus: {
          paid_out: 'Pagada',
          settled: 'Liquidada',
          reprocess: 'En reproceso',
        },
      },
      execution: {
        title: 'Ejecutar asegurados SMU',
        subtitle:
          'Al confirmar, <b>se gatillará la creación de órdenes de pago asociados,</b> a los Jobbers que se ganarán el asegurado.',
        range: 'Fecha de inicio tareas *',
        cancel: 'Cancelar',
        submit: 'Ejecutar asegurados SMU',
        alerts: {
          create: {
            success: {
              text1: 'Proceso de ejecución de asegurados SMU se ha generado con éxito.',
              text2:
                'Ten presente que el proceso demorará un tiempo en reflejarse y enviarse el correo.',
            },
          },
        },
      },
    },
    processesList: {
      filters: {
        select: 'Seleccione',
        status: 'Estado',
        date: 'Fecha de corte',
      },
      filterSearch: { title: 'Buscar', placeholder: 'Buscar ID del proceso' },
      filterClear: { title: 'Limpiar filtros' },
      filterDatePicker: { title: 'Fecha de creación' },
      columns: {
        code: 'ID Proceso',
        creator: 'Responsable',
        cutoffAtFrom: 'Fecha inicial corte',
        cutoffAtTo: 'Fecha final corte',
        createdAt: 'Fecha de creación',
        settlementsQuantity: 'Nº de liquidaciones',
        actions: 'Acciones',
      },
      table: {
        stats: {
          payable: '{{quantity}} Por pagar',
          paid_out: '{{quantity}} Pagado',
          reprocess: '{{quantity}} En reproceso',
        },
      },
      modal: {
        delete: {
          title: 'Eliminar proceso de liquidación',
          message: `Al eliminar el proceso de liquidación {{code}}, los documentos que lo componen pasarán a estado “Por pagar”.`,
          okButton: 'Eliminar proceso',
        },
      },
      alerts: {
        delete: {
          success: `Proceso de liquidación {{code}}, eliminado con éxito`,
        },
      },
    },
    moneyOrderHistory: {
      header: {
        title: 'ID OP',
        subtitle: {
          job: 'ID Tarea',
          application: 'ID Application',
        },
        creator: 'Creado por {{creator}}',
        settlementId: 'ID Liquidación {{settlementId}}',
        shopperCode: 'Nº pedido {{code}}',
        shopperOrderTypes: {
          picker: 'Picker',
          driver: 'Driver',
          shopper: 'Shopper',
        },
        jobber: {
          title: 'Jobber',
          name: 'Nombre',
          document: 'Documento',
          email: 'Email',
        },
      },
      description: {
        total: 'Pago total $t(global.currencySymbol) ',
      },
      details: {
        jobberPaymentDetail: 'Detalle Pago Jobber',
        jobPublishedDetail: 'Detalle Tarea Publicada',
        paymentSchema: 'Esquema de pago',
        paymentBy: 'Pago por',
        total: 'Pago total',
        dueDate: 'Vencimiento {{date}}',
        alert: {
          title: 'Motivo de pago bloqueado',
        },
      },
      records: {
        title: 'Historial',
        subtitle: 'Revisa el historial de movimientos en relación al pago.',
        empty: 'Sin movimientos',
        item: {
          tag: {
            updateStatus: {
              modifiedBy: 'Modificado por',
            },
          },
          title: {
            titleByRecordType: {
              update_status: 'Cambio estado orden de pago',
            },
          },
        },
      },
      footer: {
        startAt: 'Ejecución tarea: ',
      },
    },
    manualPaymentOrder: {
      create: {
        title: 'Crear orden de pago manual',
        subtitle: 'Selecciona el Jobber y luego el tipo de orden.',
        button: 'Crear orden manual',
      },
      massive: {
        title: 'Crear orden de pago masiva',
        subtitle: 'Carga la plantilla con los Jobbers. Si no la tienes, descárgala a continuación.',
        button: 'Crear orden masiva',
        download: 'Descargar plantilla base',
        downloadLink: {
          develop: `https://tj-public-assets-dev.s3.amazonaws.com/massive.csv?${new Date().getTime()}`,
          staging: `https://tj-public-assets-stg.s3.amazonaws.com/massive.csv?${new Date().getTime()}`,
          production: `https://tj-public-assets.s3.amazonaws.com/massive.csv?${new Date().getTime()}`,
        },
        titleUpload: 'Orden de pago masiva',
        uploadButton: 'Cargar plantilla',
        info: 'El código de la tarea es obligatorio y los comentarios opcionales.',
      },
      alerts: {
        payment: 'Tipo de pago que verá el Jobber en la App.',
        discount: 'Tipo de cobro que verá el Jobber en la App.',
        create: 'Orden de pago manual creada con éxito',
      },
      reasons: [
        'Ajuste Diferencia KMS',
        'Ajuste Diferencia SKU',
        'Ajuste Diferencia Unidades',
        'Ajuste Diferencia Asegurado',
        'Ajuste Diferencia Incentivo',
        'Pago extra especial',
      ],
      inputs: {
        jobbers: {
          label: 'Jobber *',
          placeholder: 'Busca por Jobber, RUT o email',
          required: 'Campo obligatorio',
        },
        comment: {
          label: 'Comentario (opcional)',
        },
        reason: {
          label: 'Motivo *',
          required: 'Campo obligatorio',
        },
        codeJob: {
          label: 'Código de tarea *',
          errorNoCode: 'Para continuar, ingresa el código de la tarea.',
          errorNotFound: 'El código ingresado no existe. Ingresa uno válido para continuar.',
          errorNoApplication:
            'El Jobber <strong>{{jobber}}</strong>, no tiene una Application en estado Asignada, Presente, Ausente o Finalizada en esa tarea, por lo que no puede ser agregada.',
          errorVerticalEST:
            'El código ingresado corresponde a una tarea de vertical <strong>Trabajos con contrato</strong> y este tipo de tarea no genera órdenes de pago. Ingresa un código de otra vertical para continuar.',
        },
        kind: {
          label: 'Tipo de orden',
          options: {
            payment: 'Pago',
            discount: 'Cobro',
          },
          required: 'Campo obligatorio',
        },
        typeShopper: {
          options: {
            shopper: 'Shopper',
            picker: 'Picker',
            driver: 'Driver',
          },
        },
        paymentType: {
          label: 'Tipo de pago',
          options: {
            incentive: 'Incentivo',
            adjustment: 'Ajuste',
            jobber_pack: 'Jobber Pack',
          },
          required: 'Campo obligatorio',
        },
        discountType: {
          label: 'Tipo de cobro',
          options: {
            jobber_pack: 'Jobber Pack',
            adjustment: 'Ajuste',
            incentive: 'Incentivo',
          },
          required: 'Campo obligatorio',
        },
        total: {
          paymentLabel: 'Monto a pagar *',
          discountLabel: 'Monto a cobrar *',
          required: 'Campo obligatorio',
        },
        dueDate: {
          label: 'Fecha de vencimiento *',
          required: 'Campo obligatorio',
        },
      },
      details: {
        payment: {
          title: 'Pago',
          total: 'Total pago',
          date: 'Fecha pago {{date}}',
        },
        discount: {
          title: 'Descuento',
          total: 'Total descuento',
          date: 'Fecha pago {{date}}',
        },
        paymentSchema: {
          name: 'Esquema de pago:',
          type: 'Tipo de monto:',
        },
        comment: 'Comentario:',
        paymentType: {
          gross: 'Monto $t(global.enums.paymentType.gross)',
          liquid: 'Monto $t(global.enums.paymentType.liquid)',
        },
      },
    },
    manualPaymentOrderShopper: {
      inputs: {
        kind: {
          label: 'Tipo de orden',
          options: {
            payment: 'Pago',
            discount: 'Cobro',
          },
          required: 'Campo obligatorio',
        },
      },
      details: {
        title: {
          payment: 'Pago',
          invoice: 'Cobro',
        },
        reasons: {
          kms_difference: 'Ajuste Diferencia KMS',
          sku_difference: 'Ajuste Diferencia SKU',
          units_difference: 'Ajuste Diferencia Unidades',
          secure_difference: 'Ajuste Diferencia Asegurado',
          incentive_difference: 'Ajuste Diferencia Incentivo',
          extra_payment: 'Pago extra especial',
        },
        additionalExpenses: {
          toll: 'Peaje',
          parking: 'Estacionamiento',
          other: 'Otro',
        },
        quantities: {
          kms_difference: '{{count}} KM',
          kms_difference_plural: '{{count}} KMS',
          sku_difference: '{{count}} SKU',
          units_difference: '{{count}} Unidad',
          units_difference_plural: '{{count}} Unidades',
        },
        tags: {
          clientInvoice: {
            title: 'Cobro a cliente',
            color: 'magenta',
          },
        },
        comment: 'Comentario:',
        paymentType: {
          gross: 'Monto $t(global.enums.paymentType.gross)',
          liquid: 'Monto $t(global.enums.paymentType.liquid)',
        },
        type: {
          incentive: 'Asegurado',
        },
        jobberTotal: 'Generado por el Jobber {{value}}',
        insuredTotal: 'Monto definido de Asegurado {{value}}',
      },
    },
    settlementHistory: {
      header: {
        title: 'Acumulado',
        jobber: {
          title: 'Jobber',
          name: 'Nombre',
          document: 'Documento',
          email: 'Email',
        },
      },
      description: {
        status: {
          payable: {
            retention: 'Retención',
            amountPayable: 'Monto a pagar',
            amountPaid: 'Monto pagado',
            amountPaidPopoverTitle: 'Monto pagado',
            amountPaidPopoverBody:
              'Este monto puede ser cargado manualmente, por lo podría existir una diferencia.',

            cutoffAtFrom: 'Fecha inicial corte',
            cutoffAtTo: 'Fecha final corte',
            settlementDate: 'Fecha de liquidación',
          },
          paid_out: {
            payed_amount: 'Monto pagado',
            payment_date: 'Fecha de pago',
          },
          reprocess: {
            alertText: 'Jobber tiene problemas con la cuenta de pago.',
          },
        },
        banking: {
          title: 'Cuenta de déposito',
          accountNumber: 'Nº',
          accountType: {
            cuenta_vista_rut: 'Cuenta vista/rut',
            corriente: 'Cuenta corriente',
            ahorro: 'Cuenta de ahorro',
          },
        },
      },
      body: {
        moneyOrderCode: 'ID Orden de pago',
        total: 'Pago total',
        payment: 'Pago',
        invoice: 'Cobro',
        paymentDetail: 'Ver orden de pago',
        shopperOrderCode: 'Pedido {{code}}',
        jobCode: 'ID tarea {{code}}',
        manualReasonQuantity: {
          kms_difference: '{{quantity}} KMS',
          sku_difference: '{{quantity}} SKU',
          units_difference: '{{quantity}} Uni.',
        },
        additionalExpenses: {
          toll: 'Peaje',
          parking: 'Estacionamiento',
          other: 'Otro',
        },
        reason: {
          kms_difference: 'Ajuste diferencia KMS',
          sku_difference: 'Ajuste diferencia SKU',
          units_difference: 'Ajuste diferencia unidades',
          secure_difference: 'Ajuste diferencia asegurado',
          incentive_difference: 'Ajuste diferencia incentivo',
          extra_payment: 'Pago extra especial',
          jobber_pack: 'Jobber Pack',
          adjustment: 'Ajuste',
          incentive: 'Incentivo',
        },
        labour: 'Tareas',
        delivery: 'Pedidos',
        others: 'Otros',
        insured: {
          insuredTotal: 'Monto definido de asegurado',
          jobberTotal: 'Ganancias por el Jobber',
          total: 'Total pago',
        },
        additionalExpensesComment: 'Detalle',
      },
      records: {
        title: 'Historial',
        subtitle: 'Revisa el historial de los cambios de estados.',
        empty: 'Sin movimientos',
        changeStatus: 'Cambio de estado de liquidación',
        modifiedBy: 'Modificado por',
      },
    },
    createSettlementProcess: {
      title: 'Generar proceso de liquidación',
      info: 'Órdenes de pago en estado “Por pagar”, con fecha menor o igual a la fecha de corte seleccionada, pasarán a estado “Liquidado” y  a la sección “Liquidaciones”.',
      info2: 'Órdenes de pago en estado “En reproceso” o “Pago bloqueado”, no serán consideradas.',
      button: 'Generar liquidación',
      alerts: {
        info: 'Si el monto a pagar es negativo, no se considerará en el pago del Jobber.',
        create:
          'Liquidación generada con éxito. Hemos enviado un correo con el detalle de cada liquidación.',
      },
      cutoffAtFrom: {
        label: 'Fecha inicial de corte *',
        required: 'Campo obligatorio',
        placeholder: 'día / mes / año',
      },
      cutoffAtTo: {
        label: 'Fecha final de corte *',
        required: 'Campo obligatorio',
        placeholder: 'día / mes / año',
      },
      jobber: {
        label: 'Jobber (opcional)',
      },
      enterprise: {
        label: 'Cliente (opcional)',
      },
      store: {
        label: 'Sede (opcional)',
      },
      error: {
        title: 'Error al generar proceso de liquidaciones',
        info: 'Se ha producido un error ({{errorCode}}) al generar el proceso de liquidaciones, intenta nuevamente. Si el problema persiste contáctate con soporte.',
        button: 'Entendido',
      },
    },
    schemaVariables: {
      hour: 'Hora por pagar',
      hour_plural: 'Horas por pagar',
      lap: 'Vuelta',
      order: 'Pedido',
      order_plural: 'Pedidos',
      units: 'Unidad',
      units_plural: 'Unidades',
      kms: 'KMS',
      sku: 'SKU',
    },
  },
};
